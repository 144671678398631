import { fetchStudios, fetchTags } from '@/api/global'

const global = {
  state: {
    activeDialog: [],
    studios: [],
    tags: [],
  },

  mutations: {
    SET_STUDIOS: (state, { data }) => {
      state.studios = data
    },

    SET_TAGS: (state, { data }) => {
      state.tags = data
    },

    OPEN_DIALOG: (state, { name }) => {
      state.activeDialog.push(name)
    },

    CLOSE_DIALOG: state => {
      let index = state.activeDialog.length - 1
      if (index >= 0) {
        state.activeDialog.splice(index, 1)
      }
    },
  },

  actions: {
    getStudios({ state, commit }) {
      if (state.studios.length !== 0) {
        return
      }
      fetchStudios().then(res => {
        let { data } = res
        commit({
          type: 'SET_STUDIOS',
          data,
        })
      })
    },

    getTags({ state, commit }) {
      if (state.tags.length !== 0) {
        return
      }
      let params = { target: 'member' }
      fetchTags(params).then(res => {
        let { data } = res
        commit({
          type: 'SET_TAGS',
          data,
        })
      })
    },
  },

  getters: {
    getStudioByName: store => {
      return name => {
        let studio = store.studios.find(obj => {
          return obj.name === `SPEEDPLAY·${name}`
        })
        return studio
      }
    },
  },
}

export default global
