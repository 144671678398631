export const BAIDU_MAP_AK = '4fd1e0a4c427ee37308a2c1195a3a26c'

export const TRAINING_TYPE = {
  elite: 'elite_training',
  group: 'group_training',
}

export const MEMBER_TYPE = {
  elite: 'elite_training_members',
  service: 'service_members',
  trial: 'trial_members',
}

export const ORDER_TYPE = {
  elite: 'elite_orders',
  group: 'group_orders',
}

export const PAGINATION_INFO = {
  pageSize: 20,
}

export const MEMBER_LIST_FILTER = {
  search: ['query'],
  membership_account: ['productType', 'accountStatus'],
  membership_purchase: ['membershipsProductType', 'saleType', 'cardId'],
  gender_and_tags: ['gender', 'tag'],
}

export const SALE_TYPES = {
  promotion: {
    cls: 'green',
    label: '促'
  },
  newbie: {
    cls: 'blue',
    label: '新',
  },
}

export const PRODUCT_TYPES = {
  elite_training: '私教',
  group_training: '团课',
}

export const MEMBERSHIP_ACCOUNT_TYPE = {
  entry: '次数卡',
  duration: '时段卡',
}

export const COURSE_CATEGORY = {
  normal: '普通',
  free: '免费',
  buyout: '包场',
  team_building: '团建',
  bring_new_free: '拉新免单',
  companion_free: '好友免单',
  special_offer: '特价课',
}

export const COACH_NAME_COLOR = ['#7ce10d', '#4a90e2', '#f67b00', '#d922ff', '#eadd00', '#50e3c2']

export const MEMBERSHIP_STATUS = {
  new: '待激活',
  active: '使用中',
  paused: '暂停',
  expired: '过期',
  used_up: '用完',
}

export const CARD_SALE_TYPE = {
  normal: '普通卡',
  promotion: '促销卡',
  newbie: '新手卡',
  quick_purchase: '直购卡',
  special_offer: '特价课促销卡'
}

export const CARD_SALE_TYPE_AND_ACTIVE = {
  'normal-active': '普通卡-激活',
  'promotion-active': '促销卡-激活',
  'newbie-active': '新手卡-激活',
  'quick_purchase-active': '直购卡-激活',
  'special_offer-active': '特价课促销卡-激活',
  'normal-pause': '普通卡-暂停',
  'promotion-pause': '促销卡-暂停',
  'newbie-pause': '新手卡-暂停',
  'quick_purchase-pause': '直购卡-暂停',
  'special_offer-pause': '特价课促销卡-暂停'
}
