<template>
  <div class="mf-overview-card" :style="{minHeight: height}">
    <el-row :gutter='16' v-if="ready">
      <el-col 
        :span="columns"
        v-for="(item, i) in data"
        :key="'card' + i"
      >
        <div 
          :class="{
            'overview-card': true, 
            'single': data.length === 1, 
            'layout-horizontal': type === 'horizontal',
            'has-background': $get(item[0], 'background'),
          }" 
          :style='{height: height, backgroundColor: item[0].background ? item[0].background : "#fff"}'
        >
          <div class="hd main" v-if="item[0]">
            <div class="label">
              <i v-if="item[0].icon" :class='iconCls(item[0].icon)'></i>
              {{item[0].label}}
            </div>
            <div class="num" :style='numStyle(item[0].color)'>
              <template v-if='item[0].render'>
                <render :render='() => item[0].render(item[0].value)'></render>
              </template>
              <template v-else>
                <div>{{ item[0].formatter ? item[0].formatter(formatFloat(item[0].value)) : formatFloat(item[0].value) }}</div>
                <div class="sub-num" v-if="item[0].subValue !== undefined">{{item[0].subValue}}</div>
              </template>
            </div>
          </div>
          <div class="bd" v-if="item.length > 1">
            <div 
              class="data"
              v-for="(data, j) in item.slice(1)"
              :key="data.label + j"
            >
              <div class="label">
                <i v-if="data.icon" :class='iconCls(data.icon)'></i>
                {{data.label}}
              </div>
              <div class="num" :style='numStyle(data.color)'>
                <template v-if='data.render'>
                  <h1>{{data.value}}</h1>
                  <render :render='() => data.render(data.value)'></render>
                </template>
                <template v-else>
                  {{data.formatter ? data.formatter(formatFloat(data.value)) : formatFloat(data.value) }}
                  <span v-if="data.span">{{data.span}}</span>
                  <span v-if="data.unit" class="unit">{{data.unit}}</span>
                  <div class="sub-num" v-if="data.subValue !== undefined">&nbsp;/ {{data.subValue}}</div>
                  <div class="sub-num" v-if="data.thirdValue !== undefined">&nbsp;/ {{data.thirdValue}}</div>
                </template>
              </div>
            </div>
          </div>
          <div class="tips" v-if="tips">
            <i class="el-icon-question"></i>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { formatFloat } from '@/utils/data'
import { render } from '@/components/render'

export default {
  // TODO: 增加data中href的支持 - by secret 👀
  name: 'MfOverviewCard',
  
  components: {
    render,
  },
  
  props: {
    data: Array,
    height: {
      type: String,
      default: 'auto',
    },
    type: {
      type: String,
      default: 'normal',
    },
    tips: {
      type: String,
    }
  },

  data() {
    return {}
  },

  computed: {
    columns() {
      return parseInt(24 / this.data.length, 10)
    },

    ready() {
      return Array.isArray(this.data) && this.data.every(item => item)
    }
  },

  methods: {
    numStyle(c) {
      if (!c) {
        return
      }
      return {
        color: c,
      }
    },

    iconCls(icon) {
      let clsName = `icon-${icon}`
      return {
        'mf-icon': true,
        [clsName]: true,
      }
    },

    formatFloat,
  },
}
</script>

<style lang='scss' scoped>
.mf-overview-card {
  width: 100%;
  margin-bottom: 32px;
}

.overview-card {
  padding: 16px 24px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.571428571;
  color: #c0c4cc;
  background-color: #fff;
  border: 1px solid #dcdfe6;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &.single {
    .hd {
      margin-bottom: 0;
    }

    .bd {
      justify-content: flex-start;
      position: absolute;
      left: 137px;
      top: 30px;
    }

    .data {
      margin-right: 24px;
      display: flex;
      align-items: center;
    }

    .num {
      margin-top: 0;
      margin-left: 8px;
      line-height: 1;
    }
  }

  &.has-background {
    color: #ffffff;
    border: 0;

    .num {
      color: inherit;
    }
  }
}

.tips {
  position: absolute;
  top: 16px;
  right: 24px;
}

.num {
  color: rgba(#000, 0.65);
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.hd {
  .num {
    margin-top: 15px;
    font-size: 38px;
    line-height: 38px;
    font-weight: bold;
    display: flex;
    align-items: baseline;
  }
  
  .sub-num {
    font-size: 60%;

    &:before {
      padding-right: 5px;
      padding-left: 5px;
      content: '/';
    }
  }
}

.bd {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  .num {
    margin-top: 10px;
    display: flex;
    align-items: baseline;
    .sub-num{
      padding-bottom: 0;
    }
  }

  span {
    margin-left: 4px;
    color: #c0c4cc;
    font-weight: normal;
    font-size: 14px;
  }

  .unit {
    color: #c0c4cc;
    font-weight: bold;
    line-height: 1;
  }

  .data {
    min-width: 80px;
  }

}

.icon {
  margin-right: 4px;

  &-avg {
    color: $primary;
  }

  &-minimum {
    color: $red;
  }

  &-maximum {
    color: $green;
  }
}

.layout-horizontal {
  display: flex;
  align-items: flex-end;

  .bd {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-top: 0;
    margin-left: 50px;
  }

  .main .num {
    display: flex;
    align-items: flex-end;
    line-height: 1;
  }

  .sub-num {
    color: #c0c4cc;
    font-size: 14px;
    padding-bottom: 5px;
    padding-left: 7px;

    &:before {
      padding-right: 3px;
      content: '/';
    }
  }
  
  .data {
    display: flex;
    align-items: center;

    .num {
      margin-top: 0;
      font-size: 14px;
      font-weight: normal;
      margin-left: 5px;
      color: #5f6265;
    }
  }
}
</style>