<template>
  <div class="mf-date-controller" :class="{'disabled': disabled}">
    <div class="item" :style='generateStyle("l", true)'>
      <slot name='left'>
      </slot>
    </div>
    <div class="item" :style='generateStyle("r", true)'>
      <slot name='right'>
      </slot>
    </div>
    <div class="date-controls-wrapper">
      <div class="date-controls item" :class='{hidden: actualTimeUnit === "all"}' :style='generateStyle("d")'>
        <div class="prev" @click='handleGo("prev")'></div>
        <div class="display">
          <render :render='renderDisplay'></render>
        </div>
        <div class="next" @click='handleGo("next")'></div>
      </div>
    </div>
    <el-date-picker
      v-if="actualTimeUnit === 'day'"
      type="date"
      size='small'
      class='item single-picker'
      :picker-options='pickerOptions1'
      :value='actualDate[1]'
      :clearable="false"
      :style='generateStyle("p")'
      @input='handleSinglePickerChange'
    >
    </el-date-picker>
    <el-date-picker
      v-else
      :value="actualDate"
      type="daterange"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      class="item range-picker"
      size="small"
      :clearable="false"
      :picker-options='pickerOptions'
      :unlink-panels='true'
      :style='generateStyle("p")'
      @input='handleRangePickerChange'
    />
    <mf-button-group :data='btnConfig' :value='actualTimeUnit' @change='handleClickBtn' :style='generateStyle("b")'></mf-button-group>
  </div>
</template>

<script>
import moment from 'moment'
import { generateTimeRange } from '@/utils/date'
import { render } from '@/components/render'
import { isEqual, debounce } from 'lodash'

export default {
  name: 'MfDateController',
  
  components: {
    render,
  },
  
  props: {
    date: {
      type: Array,
      default: _ => {
        return [moment().subtract(1, 'month').toISOString(), moment().toISOString()]
      }
    },
    timeUnit: {
      type: String,
      default: 'month',
    },
    orders: {
      type: Array,
      default: _ => ['l', 'd', 'r', 'b', 'p'],
      // l: left slot
      // d: display
      // r: right slot
      // b: button group
      // p: date picker
    },
    timeUnits: {
      type: Array,
      default: _ => ['week', 'month', 'all']
    },
    debounce: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    changeByTimeUnit: {
      type: Boolean,
      default: true,
    },
    allowRange: {
      type: Boolean,
      default: true,
    },
  },

  model: {
    prop: 'date',
    event: 'change',
  },

  data() {
    this.pickerOptions = { shortcuts: [1, 3, 6].map(generateTimeRange), firstDayOfWeek: 1 }
    this.pickerOptions1 = { firstDayOfWeek: 1 }
    
    return {
      actualDate: this.date,
      actualTimeUnit: this.timeUnit,
    }
  },
  
  computed: {
    btnConfig() {
      let unitMap = {
        day: '日',
        week: '周',
        month: '月',
        all: '全部',
      }
      let result = []
      this.timeUnits.forEach(s => {
        result.push({
          label: unitMap[s],
          value: s,
        })
      })

      return result
    }
  },
  
  watch: {
    timeUnit(val) {
      if (val !== this.actualTimeUnit) {
        this.actualTimeUnit = val
      }
    },

    actualDate(val) {
      this.emitChangeDate()
    },

    date(val) {
      this.actualDate = val
    },
  },
  
  created() {
    this.emitChangeDate = debounce(_ => {
      this.$emit('change', this.actualDate)
    }, this.debounce)
  },
  
  methods: {
    renderDisplay() {
      let [from, to] = this.actualDate
      let unit = this.actualTimeUnit
      let showFrom = moment(from).format('MM-DD')
      let showTo = moment(to).format('MM-DD')
      if (unit === 'day') {
        let Arr = ['一', '二', '三', '四', '五', '六', '日']
        let weekOfDay = '周' + Arr[moment(to).isoWeekday() - 1]
        return (<div>{showTo} {weekOfDay}</div>)
      } else if (unit === 'week') {
        return (<div>{showFrom} ~ {showTo}</div>)
      } else if (unit === 'month') {
        let month = moment(showTo).format('M') + '月'
        return (<div>{month}</div>)
      } else if (unit === 'range') {
        return (<div>{moment(from).format('YYYY-MM-DD')} ~ {moment(to).format('YYYY-MM-DD')}</div>)
      } else {
        return (<div></div>)
      }
    },

    generateStyle(order, isSlot) {
      let index = this.orders.findIndex(s => s === order)
      if (index === -1) {
        return {
          display: 'none',
        }
      }
      return {
        order: index,
      }
    },
    
    handleGo(direction) {
      if (this.actualTimeUnit === 'range') {
        this.actualTimeUnit = 'day'
      }
      let methodName = direction === 'prev' ? 'subtract' : 'add'
      let [from, to] = this.actualDate
      let unit = this.formatactualTimeUnit(this.actualTimeUnit)
      let day = moment(from)[methodName](1, this.actualTimeUnit)
      let targetFrom = day.startOf(unit).toISOString()
      let targetTo = day.endOf(unit).toISOString()
      this.actualDate = [targetFrom, targetTo]
      this.$emit(direction)
    },

    handleClickBtn(unit) {
      if (!this.changeByTimeUnit) {
        this.actualTimeUnit = unit
        this.$emit('update:timeUnit', unit)
        return
      }
      if (unit === 'all') {
        this.actualDate = [new Date(0), new Date()]
      } else {
        let day = this.actualDate[1]
        let formatedUnit = this.formatactualTimeUnit(unit)
        let from = moment(day).startOf(formatedUnit).toISOString()
        let to = moment(day).endOf(formatedUnit).toISOString()
        this.actualDate = [from, to]
      }
      this.actualTimeUnit = unit
      this.$emit('update:timeUnit', unit)
    },

    handleSinglePickerChange(date) {
      this.actualDate = [date, date]
    },

    handleRangePickerChange(date) {
      if (this.allowRange) {
        this.actualTimeUnit = 'range'
      }
      this.actualDate = date
      this.$emit('changeDatePicker', date)
    },

    formatactualTimeUnit(unit) {
      if (unit === 'week') {
        return 'isoWeek'
      }
      return unit
    },
    
    format(obj) {
      if (moment.isMoment(obj)) {
        return obj.format('YYYY-MM-DD')
      } else {
        return moment(obj).format('YYYY-MM-DD')
      }
    },
  },

  filters: {
    format(obj) {
      return this.format(obj)
    }
  }
}
</script>

<style lang='scss' scoped>
.mf-date-controller {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 -4px;
  width: calc(100% + 8px);

  .item {
    margin: 0 4px;

    &:empty {
      display: none;
    }

    .item:first-child {
      margin-left: 0;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.date-controls {
  display: flex;
  align-items: center;
}

.prev,
.next {
  width: 32px;
  height: 32px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 1;
  position: relative;
  background-color: #ffffff;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid currentColor;
    border-left: 0;
    border-top: 0;
    transform: rotate(-45deg);
    margin-left: -2px;
  }

  &.disabled {
    color: rgba(#5f6265, .4);
    border-color: rgba(#dcdfe6, .4);
    background-color: rgba(#eee, .4);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.prev:before {
  transform: rotate(135deg);
  margin-left: 2px;
}

.display {
  color: #5f6265;
  font-size: 24px;
  line-height: 32px;
  margin: 0 24px;
  user-select: none;

  div {
    min-width: 3.5rem;
    text-align: center;
  }
}

.date-controls-wrapper {
  flex: 1;
  min-height: 32px;
}

.date-controls {
  @include center(x)
}

.single-picker {
  width: 230px;
}

.range-picker {
  width: 230px;

  /deep/ .el-range__close-icon {
    width: 0;
  }

  /deep/ .el-range-separator {
    width: auto;
  }
}

/deep/ .el-button-group .el-button {
  padding-left: 15px;
  padding-right: 15px;
}
</style>