<template>
  <el-menu class="navbar" mode="horizontal">
    <img :src="logoUrl" alt="logo" class="logo">
    <breadcrumb></breadcrumb>
    <el-dropdown class="avatar-container" trigger="click">
      <div class="avatar-wrapper">
        <strong class="name-avatar">{{name}}</strong>
        <i class="el-icon-caret-bottom"></i>
      </div>
      <el-dropdown-menu class="user-dropdown" slot="dropdown">
        <router-link class="inlineBlock" to="/">
          <el-dropdown-item>
            <i class='icon icon-home'></i> Home
          </el-dropdown-item>
        </router-link>
        <div @click="openDialog('editPassword')" style="display:block;">
          <el-dropdown-item divided>
            <i class='icon icon-qrcode'></i> Edit Password
          </el-dropdown-item>
        </div>
        <div @click="logout" style="display:block;">
          <el-dropdown-item divided>
            <i class='icon icon-logout'></i> LogOut
          </el-dropdown-item>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <EditPassword 
      @close="closeDialog" />
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import EditPassword from '../components/EditPassword'

export default {
  components: {
    Breadcrumb,
    EditPassword
  },
  computed: {
    ...mapGetters([
      'avatar',
      'name'
    ]),

    logoUrl() {
      return require('@/assets/images/logo_black.png')
    },
  },
  methods: {
    openDialog(name) {
      this.$store.commit({
        type: 'OPEN_DIALOG',
        name,
      })
    },

    closeDialog() {
      this.$store.commit('CLOSE_DIALOG')
    },

    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 50px;
  line-height: 50px;
  border-radius: 0px !important;
  display: flex;
  align-items: center;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 50px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .avatar-wrapper {
      cursor: pointer;
      margin-top: 5px;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 20px;
        font-size: 12px;
      }
    }
  }
}

.logo {
  width: 120px;
  height: auto;
  margin-left: 40px;
  margin-right: 35px;
}
</style>
