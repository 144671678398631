<template>
  <el-select 
    v-model="studioId" 
    placeholder="门店选择"
    size='small'
    class="item"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { fetchStudios } from '@/api/global'
import fetch from '@/mixins/fetch'

export default {
  name: 'MfStudios',
  
  mixins: [fetch],
   
  props: {
    value: null,
    all: {
      type: Boolean,
      default: true,
    },
    defaultValueIndex: {
      type: Number,
    },
    groupTrainingStudio: {
      type: Boolean,
      default: false,
    },
    params: Object,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      studioId: this.value,
      fetchApi: params => fetchStudios(params),
    }
  },

  computed: {
    options() {
      if (!this.sourceData) {
        return
      }
      let result = this.sourceData
      // if (this.groupTrainingStudio) {
      //   result = [{
      //     id: -1,
      //     name: '全部团课门店',
      //   }, ...result]
      // }
      if (this.all) {
        result = [{
          id: '',
          name: '全部',
        }, ...result]
      }
      return result
    },
  },
  
  watch: {
    studioId(val) {
      this.change(val)
    },
  },
  
  methods: {
    change(value) {
      this.$emit('change', value)
    },

    makeParams() {
      if (this.params) {
        return this.params
      } else {
        return {
          groupTrainingStudio: this.groupTrainingStudio,
        }
      }
    },

    fetchCb(res) {
      let index = this.defaultValueIndex
      if (index && !this.value) {
        this.studioId = res.data[index - 1].id
      }      
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
