<template>
  <div :class="{'mf-card': true, pointer: this.url}" @click='handleClick'>
    <div class="banner-wrapper" :style='bannerStyle'>
      <div class="controls compact-row jc-between">
        <div class="col-6 d-flex control">
          <slot name='left-controls'></slot>
        </div>
        <div class="col-6 d-flex control jc-end">
          <slot name='right-controls'></slot>
        </div>
        <div class="absolute-center control">
          <slot name='center-controls'></slot>
        </div>
      </div>
    </div>

    <slot name='content'></slot>
  </div>
</template>

<script>
export default {
  name: 'MfCard',
  
  props: {
    height: {
      type: Number,
      default: 129,
    },

    bannerUrl: String,

    url: String,
  },

  data() {
    return {}
  },

  computed: {
    bannerStyle() {
      return {
        backgroundImage: `url(${this.bannerUrl})`,
        height: this.height + 'px',
      }
    },
  },

  methods: {
    handleClick() {
      if (this.url) {
        this.$router.push(this.url)
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.mf-card {
  box-shadow: 0 4px 20px rgba(#000, 0.02);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
}

.banner-wrapper {
  background: #dcdfe6 no-repeat 50% 50% / cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .controls {
    display: none;
    position: relative;
    height: 100%;
    z-index: 1;
    padding: 16px;
  }

  &:hover {
    &:before {
      background-color: rgba(#000, 0.2);
    }

    & > .controls {
      display: flex;
    }
  }
}

.control {
  & > * {
    display: flex;
    // align-items: center;
    justify-content: center;
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ffffff;
    font-size: 12px;
    cursor: pointer;

    & + i {
      margin-left: 8px;
    }
  }
}

.pointer {
  cursor: pointer;
}
</style>