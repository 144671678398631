import Layout from 'views/layout/Layout';

export default {
  path: '/members',
  redirect: '/members/list',
  name: 'members',
  component: Layout,
  title: '会员管理',
  permission: ['member'],
  hidden: false,
  icon: 'member',
  children: [
    // {
    //   path: 'playground',
    //   title: 'for test',
    //   component: () => import('views/layout/Playground'),
    //   name: 'playground',
    // },
    {
      path: 'list',
      component: () => import('views/member/List'),
      title: '会员列表',
      keepAlive: true,
      name: 'MemberList',
      permission: ['member', 'member'],
      hidden: false,
      beforeEnter: (to, from, next) => {
        if (from.path.includes('trial_member') && !from.path.includes('trial_member/list')) {
          next('/members/trial_member/list');
        } else {
          next();
        }
      },
    },
    {
      path: ':memberId',
      redirect: '/members/:memberId/membership',
      component: () => import('views/member/Detail'),
      name: 'memberDetail',
      title: '会员详情',
      children: [
        {
          path: 'membership',
          component: () => import('views/member/detail/Membership'),
          name: 'memberDetailMembership',
          title: '余额统计',
          children: [
            {
              path: 'group_memberships',
              component: () => import('views/member/record/GroupMemberships'),
              name: 'memberRecordGroupMemberships',
              title: '会员卡使用记录',
              root: true,
            },
          ],
        },
        {
          path: 'training',
          component: () => import('views/member/detail/Training'),
          name: 'memberDetailTraining',
          title: '训练统计',
          children: [
            {
              path: 'group_training',
              component: () => import('views/member/record/GroupTraining'),
              name: 'memberRecordGroupTraining',
              title: '训练记录',
              root: true,
            },
          ],
        },
        {
          path: 'order',
          component: () => import('views/member/detail/Order'),
          name: 'memberDetailOrder',
          title: '消费统计',
          children: [
            {
              path: 'group_order',
              component: () => import('views/member/record/GroupOrder'),
              name: 'memberRecordGroupOrder',
              title: '团课订单',
              root: true,
            },
          ],
        },
        {
          path: 'account_member/list',
          component: () => import('views/member/components/AccountMembers'),
          name: 'membershipAccountMemberList',
          title: '共享卡管理',
          root: true,
        },
      ],
    },
    {
      path: 'trial_member/list',
      hidden: false,
      title: '新手卡会员',
      keepAlive: true,
      permission: ['member', 'trial_member'],
      name: 'memberTrialMemberList',
      component: () => import('views/trial_member/List'),
    },
    {
      path: 'trial_member/chart',
      title: '新手卡会员图表',
      component: () => import('views/trial_member/Chart'),
      name: 'trial_memberChart',
    },
    {
      path: 'communication/list',
      hidden: false,
      title: '沟通记录',
      keepAlive: true,
      permission: ['member'],
      name: 'memberCommunication',
      component: () => import('views/member/communication/Index'),
    },
    {
      path: 'trial_member/:trial_memberId',
      redirect: '/members/trial_member/:trial_memberId/membership',
      component: () => import('views/member/Detail'),
      name: 'trialMemberDetail',
      title: '新手卡会员详情',
      children: [
        {
          path: 'membership',
          component: () => import('views/member/detail/Membership'),
          name: 'trialMemberMembership',
          title: '余额统计',
          children: [
            {
              path: 'group_memberships',
              component: () => import('views/member/record/GroupMemberships'),
              name: 'trialMemberGroupMembership',
              title: '会员卡使用记录',
              root: true,
            },
          ],
        },
        {
          path: 'training',
          component: () => import('views/member/detail/Training'),
          name: 'trialMemberTraining',
          title: '训练统计',
          children: [
            {
              path: 'group_training',
              component: () => import('views/member/record/GroupTraining'),
              name: 'trialMemberGroupTraining',
              title: '训练记录',
              root: true,
            },
          ],
        },
        {
          path: 'order',
          component: () => import('views/member/detail/Order'),
          name: 'trialMemberOrder',
          title: '消费统计',
          children: [
            {
              path: 'group_order',
              component: () => import('views/member/record/GroupOrder'),
              name: 'trialMemberGroupOrder',
              title: '团课订单',
              root: true,
            },
          ],
        },
      ],
    },
  ],
};
