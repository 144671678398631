import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'

export default {
  path: '/operating_tools',
  redirect: '/operating_tools/list',
  component: Layout,
  name: 'operatingTools',
  title: '运营工具',
  hidden: false,
  permission: ['operating_tools'],
  icon: 'tool',
  children: [
    {
      path: 'advertisements',
      component: RouterView,
      name: 'operatingToolsAdvertisement',
      redirect: '/operating_tools/advertisements/detail',
      permission: ['operating_tools', 'ad'],
      title: '广告管理',
      hidden: false,
      children: [
        {
          path: 'detail',
          component: () => import('views/operating_tools/advertisements/Detail'),
          name: 'operatingToolsAdvertisementDetail',
          title: '广告详情',
        },
        {
          path: 'add',
          component: () => import('views/operating_tools/advertisements/Add'),
          name: 'operatingToolsAdvertisementAdd',
          title: '添加广告',
        },
        {
          path: 'edit',
          component: () => import('views/operating_tools/advertisements/Edit'),
          name: 'operatingToolsAdvertisementEdit',
          title: '编辑广告',
        },
      ],
    },
    {
      path: 'scheduler',
      component: () => import('views/operating_tools/scheduler/Index'),
      name: 'operatingToolsSchedulerIndex',
      title: '预约管理',
      permission: ['operating_tools', 'group_training_schedule'],
      hidden: false,
    },
    {
      path: 'member_purchase_analysis',
      component: () => import('views/operating_tools/member_purchase/Analysis'),
      name: 'operatingToolsMemberPurchaseAnalysis',
      title: '全量会员卡',
      permission: ['operating_tools', 'member_purchase_analysis'],
      hidden: false,
    },
    {
      path: 'referrals',
      component: () => import('views/operating_tools/referrals/Index'),
      name: 'operatingToolsMemberReferrals',
      title: '运营记录',
      hidden: false,
      permission: ['operating_tools', 'member_referrals'],
    },
    {
      path: 'rating',
      component: () => import('views/operating_tools/rating/Index'),
      title: '评价管理',
      keepAlive: false,
      name: 'operatingToolsRating',
      permission: ['operating_tools', 'ratings'],
      hidden: false,
    },
    {
      path: 'reviews',
      component: () => import('views/operating_tools/reviews/Index'),
      title: '评论管理',
      keepAlive: false,
      name: 'operatingToolsReviews',
      permission: ['operating_tools', 'reviews'],
      hidden: false,
    },
    {
      path: 'coupon',
      component: () => import('views/operating_tools/coupon/Index'),
      title: '优惠券管理',
      keepAlive: false,
      name: 'operatingCourseCoupon',
      permission: ['operating_tools', 'coupon'],
      hidden: false,
    },
    {
      path: 'dashboard',
      component: () => import('views/operating_tools/dashboard/Index'),
      title: '运营工作台',
      keepAlive: false,
      name: 'operatingDashboard',
      permission: ['operating_tools', 'dashboard'],
      hidden: false,
    },
    {
      path: 'order_group',
      component: () => import('views/operating_tools/order_group/Index'),
      title: '团购管理',
      keepAlive: true,
      name: 'operatingOrderGroup',
      // permission: ['operating_tools', 'ratings'],
      hidden: false,
    },
  ],
}

