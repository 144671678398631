import Layout from 'views/layout/Layout'

export default {
  path: '/membership',
  redirect: '/membership/cards',
  name: 'membership',
  component: Layout,
  title: '会员卡管理',
  hidden: false,
  icon: 'card',
  permission: ['membership'],
  children: [
    {
      path: 'cards',
      component: () => import('views/membership/card/List'),
      name: 'membershipCardList',
      title: '会员卡管理',
      hidden: false,
      permission: ['membership', 'card'],
    },
    {
      path: 'order',
      component: () => import('views/membership/order/Detail'),
      name: 'membershipOrderDetail',
      title: '订单列表',
      hidden: false,
      permission: ['membership', 'membership_order'],
    },
    {
      path: 'refund',
      component: () => import('views/membership/order/Detail?tab=refund'),
      name: 'membershipRefundDetail',
      title: '退款列表',
      permission: ['membership', 'refund'],
    },
    {
      path: 'order/add',
      component: () => import('views/membership/order/OrderAdd'),
      name: 'membershipOrderOrderAdd',
      title: '创建订单',
      permission: ['membership', 'membership_order'],
    },
    {
      path: 'refund/add',
      component: () => import('views/membership/order/RefundAdd'),
      name: 'membershipOrderRefundAdd',
      title: '创建退款',
      // permission: ['membership', 'refund'],
    },
    {
      path: 'charge',
      component: () => import('views/membership/charge/List'),
      name: 'membershipChargeList',
      title: '充值列表',
      hidden: false,
      permission: ['membership', 'membership'],
    },
    {
      path: 'charge/add',
      component: () => import('views/membership/charge/Add'),
      name: 'membershipChargeAdd',
      title: '创建新充值',
      permission: ['membership', 'membership'],
    },
    {
      path: 'card/:cardId/detail',
      component: () => import('views/membership/card/Detail'),
      name: 'membershipCardDetail',
      title: '会员卡详情',
      permission: ['membership', 'card'],
    },
    {
      path: 'new',
      component: () => import('views/membership/card/MembershipCardNew'),
      name: 'membershipMembershipCardNew',
      title: '新建卡种',
      permission: ['membership', 'card'],
    },
    {
      path: ':cardId/edit',
      component: () => import('views/membership/card/MembershipCardEdit'),
      name: 'membershipMembershipCardEdit',
      title: '编辑卡种',
      permission: ['membership', 'card'],
    },
    {
      path: 'card/:cardId/new',
      component: () => import('views/membership/card/New'),
      name: 'membershipCardNew',
      title: '新建会员卡',
      permission: ['membership', 'card'],
    },
    {
      path: 'card/:cardId/:optionId/edit',
      component: () => import('views/membership/card/Edit'),
      name: 'membershipCardEdit',
      title: '编辑会员卡',
      permission: ['membership', 'card'],
    },
  ],
}
