import Layout from 'views/layout/Layout'

export default {
  path: '/workout_outlines',
  redirect: '/workout_outlines/list',
  component: Layout,
  name: 'workoutOutlines',
  title: '训练流管理',
  icon: 'dumbbell',
  children: [
    {
      path: 'list',
      component: () => import('views/workout_outline/List'),
      name: 'workout_outlineList',
      title: '课程内容列表',
      icon: 'dumbbell',
      keepAlive: true,
      name: 'workoutOutlineList',
    },
    {
      path: ':workoutOutlineId',
      // redirect: '/workout_outlines/:workoutOutlineId/outline',
      component: () => import('views/workout_outline/Detail'),
      name: 'workout_outlineDetail',
      title: '课程内容详情',
      children: [
        {
          path: 'outline',
          component: () => import('views/workout_outline/outline/Outline'),
          name: 'workout_outlineDetailOutline',
          title: '课程内容',
        },
        {
          path: 'timeline',
          component: () => import('views/workout_outline/timeline/Timeline'),
          name: 'workout_outlineDetailTimeline',
          title: '课程时间线',
        },
        {
          path: 'default_playlist',
          component: () => import('views/workout_outline/default_playlist/DefaultPlaylist'),
          name: 'workout_outlineDetailDefaultPlaylist',
          title: '课程默认歌单',
        },
      ],
    },
  ],
}
