<template>
  <div class='mf-editor-wrapper'>
    <quill-editor v-loading='uploadImgLoading' v-model="content" ref="quillEditor"></quill-editor>
    <input type="file" @change='uploadImage' ref='hiddenInput' class='hidden-input'>
  </div>
</template>

<script>
import { uploadQuilleditorImages } from '@/api/global'
import Quill from 'quill'
import { uniqueId } from "lodash";

export default {
  name: 'MfEditor',

  props: {
    value: null,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      content: this.value,
      uploadImgLoading: false,
      inputId: uniqueId('upload'),
    }
  },

  watch: {
    content(val) {
      this.change(val)
    },

    value(val) {
      if (val !== this.content) {
        this.content = val
      }
    },
  },

  mounted() {
    let vm = this
    let input = this.$refs.hiddenInput
    setTimeout(() => {
      this.$refs.quillEditor.quill.getModule('toolbar').addHandler('image', imgHandler)
    }, 1000)

    function imgHandler(image) {
      vm.addImgRange = vm.$refs.quillEditor.quill.getSelection()
      if (image) {
        console.log(this.$refs)
        let el = input
        el.click()
      }
    }
  },

  methods: {
    uploadImage(e) {
      let filter = /image\/jpeg|image\/jpg|image\/png|image\/gif/i
      let reader = new FileReader()
      let file = event.target.files[0]

      if (!filter.test(file.type)) {
        this.$message({
          message: '文件类型必须为jpeg、jpg、png、gif中的一种！',
          type: 'error',
        })
      }
      reader.readAsDataURL(file)
      reader.onloadend = _ => {
        this.uploadImgLoading = true
        uploadQuilleditorImages({ image: reader.result }).then(res => {
          let url = res.data
          this.insertImg(url)
        })
      }
    },

    insertImg(url) {
      this.addRange = this.$refs.quillEditor.quill.getSelection()
      url = url.indexOf('http') !== -1 ? url : 'http:' + url
      this.$refs.quillEditor.quill.insertEmbed(
        this.addRange !== null ? this.addRange.index : 0,
        'image',
        url,
        Quill.sources.USER
      )
      this.uploadImgLoading = false
    },

    change(val) {
      this.$emit('change', val)
    },
  },
}
</script>

<style lang='scss' scoped>
.mf-editor-wrapper {
  width: 400px;
}

.hidden-input {
  position: absolute;
  visibility: hidden;
}
</style>