<template>
  <div :class="tableCls">
    <div v-if="type === 'custom'">
      <slot name="customTable" :data="data"></slot>
    </div>
    <el-table
      ref='el-table'
      :data="data"
      v-loading='loading'
      v-bind="$attrs"
      v-on="$listeners"
      v-else
    >
      <template v-for='(config, i) in columnsConfig'>
        <template v-if='config.component'>
          <el-table-column v-bind='config' :key='"column" + i'>
            <template slot-scope='scope'>
              <component 
                :is="`${config.component}`" 
                v-bind="typeof config.propsData === 'function' ? config.propsData(scope.row) : config.propsData"
              />
            </template>
          </el-table-column>
        </template>
        <template v-else-if='config.render'>
          <el-table-column v-bind='config' :key="'column' + i">
            <template slot-scope='scope'>
              <render :render='h => config.render(h, scope.row)' />
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <slot v-if='config.slot' :name='config.slot' :data="data"></slot>
          <el-table-column v-else v-bind='config' :key='"column" + i' />
        </template>
      </template>
    </el-table>
    
    <div class="footer" v-if="total">
      <div class='item-total'>共 {{total}} 条</div>
      <el-pagination 
        class="table-pagination"
        background 
        :layout="`${customPageSize ? 'sizes, ' : ''}prev, pager, next`"
        :total="total" 
        :page-size="pageSize" 
        :current-page="page" 
        :pager-count='pagerCount'
        @current-change='changePage'
        @size-change="handleSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { PAGINATION_INFO } from '@/constant/entries'
import { render } from '@/components/render'

export default {
  name: 'MfTable',
  
  components: {
    render,
  },
  
  props: {
    data: Array,
    columnsConfig: {
      type: Array,
      required: true,
    },
    total: Number,
    page: Number,
    isLoading: Boolean,
    type: {
      type: String,
      validator: value => {
        return ['list', 'record', 'custom'].includes(value)
      },
    },
    pageSize: {
      type: Number,
      default: PAGINATION_INFO.pageSize,
    },
    customPageSize: {
      type: Boolean,
      default: false,
    },
    indicatorsData: {
      type: Array,
      validator: value => {
        return value.every(({ index, cls, content }) => {
          return index !== undefined
        })
      },
    },
    pagerCount: {
      type: Number,
      default: 7,
    }
  },

  data: () => {
    return {
      indicatorReady: true,
    }
  },

  computed: {
    tableCls() {
      let { type } = this
      let cls = type ? `table-${type}` : ''
      return `mf-table ${cls}`
    },

    loading() {
      if (this.indicatorsData) {
        return this.indicatorReady
      } else {
        return this.isLoading
      }
    },
  },

  mounted() {
    if (this.indicatorsData) {
      setTimeout(() => {
        this.indicatorReady = false
        this.indicatorsData.forEach(params => this.updateIndicators(params))
      }, 1300)
    }
  },

  watch: {
    indicatorsData: {
      handler: function(val) {
        this.updateAllIndicators()
      },
      deep: true,
    },
  },

  methods: {
    changePage(value) {
      this.$emit('changePage', value)
    },

    totalIndex(index) {
      return index + (this.page - 1) * this.pageSize + 1
    },

    sort({ prop, order }) {
      this.$refs['el-table'].sort(prop, order)
    },

    insertIndicator(index, cls, content) {
      let rows = this.$refs['el-table'].$el.querySelectorAll(`.el-table__row`)
      let temp = index === rows.length ? index : index + 1
      let cursor = this.$refs['el-table'].$el.querySelector(`.el-table__row:nth-child(${temp})`)
      if (!cursor) {
        return
      }
      let indicator = document.createElement('tr')
      let colspan = cursor.querySelectorAll('td').length
      indicator.className = `${cls} indicator el-table__row`
      indicator.innerHTML = `<td colspan="${colspan}">${content}</td>`
      if (rows.length === index) {
        cursor.parentElement.appendChild(indicator)
      } else {
        cursor.parentElement.insertBefore(indicator, cursor)
      }
    },

    updateIndicators({ index, cls, content }) {
      let indicators = this.$refs['el-table'].$el.querySelectorAll(`.${cls}.indicator`)
      indicators.forEach(el => el.remove())
      this.insertIndicator(index, cls, content)
    },

    updateAllIndicators() {
      if (this.indicatorsData && this.indicatorsData.length) {
        this.indicatorsData.forEach(params => this.updateIndicators(params))
      }
    },

    handleSizeChange(v) {
      this.$emit('pageSizeChange', v)
    }
  },

  inheritAttrs: false,
}
</script>

<style lang="scss" scoped>
.footer {
  color: #5f6265;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
}

/deep/ .el-pagination {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .el-pagination__sizes {
    flex: 1;
  }
}
</style>
