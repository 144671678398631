// 帮你发请求
export default {
  data: function () {
    return {
      isLoading: true,
      sourceData: null,
      autoFetch: true,
      autoWatch: false,
    }
  },

  created() {
    if (this.autoFetch) {
      this.fetchData()
    }
  },

  watch: {
    filter: {
      handler: function () {
        if (this.autoWatch) {
          let params = this.makeParams()
          this.fetchData(params)
        }
      },
      deep: true,
    },
  },

  methods: {
    fetchData(params) {
      if (!this.fetchApi) {
        return
      }
      this.isLoading = true
      if (!params) {
        params = this.makeParams()
      }
      return this.fetchApi(params).then(res => {
        this.sourceData = this.formatData(res.data)
        this.fetchCb(res)
        this.isLoading = false
      })
    },

    formatData(data) {
      return data
    },

    makeParams() {
      if (this.autoWatch) {
        let result = this.filter || {}
        return { ...result }
      } else {
        return {}
      }
    },

    fetchCb() { },

    reload() {
      this.fetchData()
    },

    openDialog(name) {
      this.$store.commit({
        type: 'OPEN_DIALOG',
        name,
      })
    },

    closeDialog() {
      this.$store.commit('CLOSE_DIALOG')
    },
  },
}
