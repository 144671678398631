import { render, staticRenderFns } from "./MfFormList.vue?vue&type=template&id=da6ab46e&scoped=true&"
import script from "./MfFormList.vue?vue&type=script&lang=js&"
export * from "./MfFormList.vue?vue&type=script&lang=js&"
import style0 from "./MfFormList.vue?vue&type=style&index=0&id=da6ab46e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da6ab46e",
  null
  
)

export default component.exports