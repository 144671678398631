import Layout from 'views/layout/Layout'

export default {
  path: '/occupancy_rate',
  name: 'courseLevel',
  redirect: '/occupancy_rate/index',
  // permission: ['bgm'],
  component: Layout,
  title: '上座率运营',
  icon: 'occupancy-rate',
  hidden: false,
  permission: ['occupancy_rate'],
  children: [
    {
      path: 'course_level',
      name: 'ORCourseLevel',
      component: () => import('views/occupancy_rate/Index'),
      title: '时段管理',
      hidden: false,
      permission: ['occupancy_rate', "time_slot"],
    },
    {
      path: 'analysis',
      name: 'ORAnalysis',
      component: () => import('views/occupancy_rate/Index'),
      title: '数据分析',
      hidden: false,
      permission: ['occupancy_rate', "analysis"],
    },
    {
      path: 'statistic_analysis',
      name: 'ORStatisticAnalysis',
      component: () => import('views/occupancy_rate/Index'),
      title: '数据统计',
      hidden: false,
      permission: ['occupancy_rate', "statistics"],
    },
  ],
}
