<template>
  <el-select 
    v-model="method" 
    placeholder="支付方式"
    @change='change'
    size='small'
    class="item"
  >
    <el-option
      v-for="item in options"
      :key="item.label"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select>  
</template>

<script>
import { PAYMENTMETHOD } from "@/constant/options"

export default {
  name: 'MfPaymentMethod',
  
  props: {
    value: null,
    all: {
      type: Boolean,
      default: true,
    },
  },
  
  data() {
    return {
      method: this.value,
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  computed: {
    options() {
      if (this.all) {
        return [{label: '全部', value: null}, ...PAYMENTMETHOD]
      } else {
        return PAYMENTMETHOD
      }
    }
  },

  methods: {
    change(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
