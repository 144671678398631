import request from 'utils/request'
import { BAIDU_MAP_AK } from '@/constant/entries'

// 门店数据
export function fetchStudios(params) {
  if (!params || (!params.active && params.active !== false)) { // 全局的api只返回激活的门店
    params = {
      ...params,
      active: true,
    }
  }
  return request({
    url: '/studios',
    method: 'get',
    params,
  })
}

// 场地数据
export function fetchZones(params) {
  return request({
    url: `/zones`,
    method: 'get',
    params,
  })
}

// 刷新场地屏幕
export function reloadScreen(studioId, screenId) {
  return request({
    url: `studios/${studioId}/screens/${screenId}/reload`,
    method: 'post',
  })
}

export function fetchTags(params) {
  return request({
    url: '/tags',
    method: 'get',
    params,
  })
}

// 会员卡列表(卡种)
export function fetchMembershipCards(params) {
  return request({
    url: '/membership_cards',
    method: 'get',
    params,
  })
}

// 会员卡列表(options)
export function fetchMembershipCardOptions(params) {
  return request({
    url: '/membership_card_options',
    method: 'get',
    params,
  })
}

// 上传quilleditor图片
export function uploadQuilleditorImages(data) {
  return request({
    url: '/quilleditor_images',
    method: 'post',
    data,
  })
}

// 课程产品列表
export function fetchProducts(params) {
  return request({
    url: '/course/products',
    method: 'get',
    params,
  })
}

// 团课产品分组列表
export function fetchWorkoutGroups(params) {
  return request({
    url: '/workout_groups',
    method: 'get',
    params,
  })
}

// 百度地图地点查询
export function bmLocalSearch(query, success, error = () => {console.log('jsonp error')}) {
  ajax({
    url: `http://api.map.baidu.com/place/v2/search?query=${query}&region='成都'&output=json&coord_type=2&ak=${BAIDU_MAP_AK}&callback=jsonpCb`,
    jsonp: 'jsonpCb',
    data: {},
    success,
    error,
  })
}

function ajax(params) {
  params = params || {};
  params.data = params.data || {};
  var json = params.jsonp ? jsonp(params) : json(params);


  // jsonp请求   
  function jsonp(params) {
    //创建script标签并加入到页面中   
    var callbackName = params.jsonp;
    var head = document.getElementsByTagName('head')[0];
    // 设置传递给后台的回调参数名   
    params.data['callback'] = callbackName;
    var data = formatParams(params.data);
    var script = document.createElement('script');
    head.appendChild(script);


    //创建jsonp回调函数   
    window[callbackName] = function (json) {
      head.removeChild(script);
      clearTimeout(script.timer);
      window[callbackName] = null;
      params.success && params.success(json);
    };


    //发送请求   
    script.src = params.url


    //为了得知此次请求是否成功，设置超时处理   
    if (params.time) {
      script.timer = setTimeout(function () {
        window[callbackName] = null;
        head.removeChild(script);
        params.error && params.error({
          message: '超时'
        });
      }, time);
    }
  };


  //格式化参数   
  function formatParams(data) {
    var arr = [];
    for (var name in data) {
      // arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
    };


    // 添加一个随机数，防止缓存   
    // arr.push('v=' + random());
    return arr.join('&');
  }


  // 获取随机数   
  function random() {
    return Math.floor(Math.random() * 10000 + 500);
  }
}
