<template>
  <div class="mf-image-upload">
    <ul class="image-list">
      <template v-for="(item, i) in fileList">
        <li class="item" :key="'file' + i">
          <div class="img-wrapper" :style="{width: width + 'px', height: height + 'px'}" v-if="type !== 'audio'">
            <img :src="(propName && !isString(item))? item[propName] : item" v-if="type === 'image'">
            <video :src="item" :width="width" :height="height" autoplay loop v-if="type === 'video'"></video>
          </div>
          <audio :src="item" v-if="type === 'audio'" controls></audio>
          <div class="action">
            <template v-if='!disableUpdate'>
              <div @click="handleUpdate(i)">更换</div>
              <div class="separator">|</div>
            </template>
            <div @click="handleDelete(i)">删除</div>
          </div>
        </li>
      </template>
      <li class="item add" @change="handleUpload" v-show="showAdd">
        <i class="el-icon-plus"></i>
        <input type="file" ref="upload">
      </li>
    </ul>
    <div class="notice">{{actualNotice}}</div>

    <mf-dialog
      ref='dialog'
      title='图片上传'
      width='550px'
      :name='dialogName'
      :append-to-body='true'
      v-if="type === 'image'"
    >
      <div class="dialog-content">
        <div class="cropper-wrapper">
          <vue-cropper
            v-bind="option" 
            ref='cropper' 
            @realTime="realTime"
          />
        </div>
        <div class="preview-wrapper">
          <h2>预览</h2>
          <div class="preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden'}">
            <div :style="previews.div">
              <img :src="previews.url" :style="previews.img">
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <el-button type="primary" size="small" @click="closeDialog">确认上传</el-button>
      </div>
    </mf-dialog>
  </div>
</template>

<script>
import VueCropper from 'vue-cropper'
import MfDialog from '@/components/MfDialog'
import { isString, isArray, uniqueId } from 'lodash'

export default {
  name: 'MfImageUpload',
  
  components: {
    MfDialog,
    VueCropper,
  },

  props: {
    value: [String, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 10 * 1000 * 1000,
    },
    type: {
      type: String,
      default: 'image',
    },
    accept: Array,
    notice: String,
    cropper: {
      type: Boolean,
      default: null,
    },
    width: {
      type: Number,
      default: 80,
    },
    height: {
      type: Number,
      default: 80,
    },
    base64: {
      type: Boolean,
      default: true,
    },
    deleteFunc: Function,
    updateFunc: Function,
    uploadFunc: Function,
    propName: {
      type: String,
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    let fileList = this.initFileList()
    return {
      fileList,
      currentIndex: null,
      previews: {},
      updateImage: false,
      dialogName: uniqueId('cropperDialog'),
      option: {
        img: '',
        outputSize: 1,
        outputType: 'png',
        autoCrop: true,
        autoCropWidth: 100,
        autoCropHeight: 100,
        fixed: true,
        canMove: false,
      },
    }
  },

  computed: {
    showAdd() {
      if (!this.fileList) {
        return
      }
      return this.multiple || this.fileList.length === 0
    },

    actualCropper() {
      return this.cropper
    },

    actualAccept() {
      if (!this.accept) {
        if (this.type === 'image') {
          return ['jpg', 'jpeg', 'png', 'gif']
        } else if (this.type === 'video') {
          return ['mp4']
        } else if (this.type === 'audio') {
          return ['mp3']
        }
      } else {
        return this.accept
      }
    },

    actualNotice() {
      if (!this.notice && this.notice !== '') {
        if (this.type === 'image') {
          return '建议图片尺寸100 * 100，大小不超过3M，支持格式png、jpg、jpeg、gif'
        } else if (this.type === 'video') {
          return '建议视频尺寸1920*1080，支持格式MP4'
        } else if (this.type === 'audio') {
          return '建议控制音乐大小，支持格式MP3'
        }
      } else {
        return this.notice
      }
    },
  },

  watch: {
    value(val) {
      console.log(val)
      this.initFileList()
    },

    fileList(val) {
      this.$refs.upload.value = ''
      this.emitChange()
    },

    value(){
      this.setFileList()
    }
  },

  methods: {
    isString,
    
    handleUpload(e) {
      let file = e.target.files[0]
      if (!file) {
        return
      }
      if (!this.validateFile(file)) {
        return
      }
      if (this.updateFunc && this.updateImage) {
        this.updateFunc(file)
        this.updateImage = false
        return
      }
      let reader = new FileReader()
      if (!this.base64) {
        this.$emit('change', file)
      } else {
        reader.onload = _ => {
          this.option.img = reader.result
          if (this.type === 'image' && this.actualCropper) {
            this.openDialog()
          } else {
            this.setFiledata(reader.result)
          }
          if (this.uploadFunc) {
            this.uploadFunc(reader.result)
            return
          }
        }
        reader.readAsDataURL(file)
      }
    },

    setFileList(){
      let { value } = this
      if (isString(value) && value) {
        this.fileList = [value]
      } else if (isArray(value)) {
        this.fileList = value
      } else {
        this.fileList = []
      }
    },

    validateFile(file) {
      let msg = ''
      if (file.type !== '') {
        let type = file.type.split('/')[1].toLowerCase()
        if (!this.actualAccept.includes(type)) {
          msg = `文件类型必须是${this.actualAccept.join('、')}的一种`
        } else if (file.size > this.size) {
          msg = `文件大小不能超过${this.size / 1000 / 1000}M`
        } else {
          return true
        }
      } else {
        msg = '格式出错'
      }
      this.$message({
        message: msg,
        type: 'error',
      })
      return false
    },

    handleUpdate(index) {
      this.currentIndex = index
      this.updateImage = true
      this.$refs.upload.click()
    },

    handleDelete(index) {
      if (this.deleteFunc) {
        this.deleteFunc(this.value[index], index)
      } else {
        this.fileList.splice(index, 1)
      }
    },

    openDialog() {
      if (this.actualCropper) {
        this.$store.commit({
          type: 'OPEN_DIALOG',
          name: this.dialogName,
        })
      }
    },

    closeDialog() {
      // this.$store.commit('CLOSE_DIALOG')
      this.$refs.dialog.$children[0].hide()
      this.$refs.cropper.getCropData(data => {
        this.setFiledata(data)
      })
    },

    setFiledata(data) {
      if (this.currentIndex !== null) {
        this.fileList.splice(this.currentIndex, 1, data)
        this.currentIndex = null
      } else {
        this.fileList.push(data)
      }
    },

    realTime(value) {
      this.previews = value
    },

    emitChange() {
      let value = this.fileList[0]
      if (this.multiple) {
        value = this.fileList
      }
      if (value === undefined) {
        value = ''
      }
      this.$emit('change', value)
    },

    initFileList() {
      let fileList = []
      let {value} = this
      if (isString(value) && value) {
        fileList = [value]
        return fileList
      }
      if (isArray(value)) {
        fileList = value
        return fileList
      } 
      return fileList
    }
  },
}
</script>

<style lang='scss' scoped>
.image-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-right: 16px;

    .img-wrapper {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.02);
      display: flex;
    }

    .action {
      color: $primary;
      font-size: 12px;
      line-height: 22px;
      margin-top: 8px;
      display: flex;
      justify-content: center;

      div {
        cursor: pointer;
      }
    }

    .separator {
      color: rgba(0, 0, 0, 0.25);
      margin: 0 3px;
    }

    img {
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.add {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  display: flex;
  border: 1px dashed #dcdfe6;
  color: rgba(0, 0, 0, 0.25);
  font-size: 20px;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  i {
    margin: auto;
  }
}

.notice {
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
  margin-top: 8px;

  &:before {
    content: '*';
  }

  &:empty {
    display: none;
  }
}

.cropper-wrapper {
  width: 100%;
  min-height: 200px;
}

.dialog-content {
  display: flex;
}

.cropper-wrapper {
  width: 240px;
  margin-right: 24px;
}

.preview-wrapper {
  width: 50%;

  h2 {
    font-size: 12px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    margin: 0;
    margin-bottom: 16px;
  }
}

.preview {
  border: 1px dashed #888;
}

/deep/ div.vue-cropper {
  width: 240px;
  min-height: 240px;
}

.btn-wrapper {
  text-align: right;
  margin-top: 24px;
}
</style>
