import Layout from 'views/layout/Layout'

export default {
  path: '/coaches',
  redirect: '/coaches/list',
  component: Layout,
  name: 'coaches',
  title: '教练管理',
  permission: ['coach'],
  icon: 'coach',
  hidden: false,
  children: [
    {
      path: 'daily_schedule',
      component: () => import('views/coach/DailySchedule'),
      name: 'coachDailySchedule',
      hidden: false,
      permission: ['coach', 'daily_schedule'],
      title: '日工作台',
    },
    {
      path: 'report/courses',
      component: () => import('views/coach/reports/CoursesReport'),
      name: 'coachReportsCoursesReport',
      hidden: false,
      permission: ['coach', 'report'],
      title: '数据报表',
    },
    {
      path: 'list',
      component: () => import('views/coach/Wrapper'),
      name: 'coachList',
      title: '教练人事',
      permission: ['coach', 'coach'],
      keepAlive: true,
      hidden: false,
    },
    {
      path: 'access_logs/list',
      component: () => import('views/coach/access_logs/List'),
      name: 'accessLogList',
      title: 'SA访问记录',
      permission: ['coach', 'access_log'],
      keepAlive: true,
      hidden: false,
    },
    {
      path: 'product',
      component: () => import('views/coach/Wrapper'),
      title: '授课管理',
      permission: ['coach', 'coach'],
      name: 'CoachProduct',
    },

    {
      path: 'price',
      component: () => import('views/coach/Wrapper'),
      title: '课时费设置汇总',
      permission: ['workout'],
      name: 'WorkoutPrice',
    },

    {
      path: 'level',
      component: () => import('views/coach/Wrapper'),
      title: '等级管理',
      permission: ['coach', 'coach'],
      name: 'CoachLevel',
    },
    {
      path: 'level/add',
      component: () => import('views/coach/LevelAdd'),
      title: '创建新的教练等级',
      permission: ['coach', 'coach'],
      name: 'CoachLevelAdd',
    },
    {
      path: 'level/edit',
      component: () => import('views/coach/LevelEdit'),
      name: 'CoachLevelEdit',
      title: '编辑教练等级',
      permission: ['coach', 'coach'],
    },
    {
      path: 'report/sales',
      component: () => import('views/coach/reports/SalesReport'),
      name: 'coachReportsSalesReport',
      title: '数据报表',
      permission: ['coach', 'report'],
    },
    {
      path: 'report/consumptions',
      component: () => import('views/coach/reports/ConsumptionsReport'),
      name: 'coachReportsConsumptionsReport',
      title: '私教耗课预估',
      permission: ['coach', 'report'],
    },
    {
      path: 'report/consumptions_statistics',
      component: () => import('views/coach/reports/ConsumptionsStatistics'),
      name: 'coachReportsConsumptionsStatistics',
      title: '私教耗课统计',
      permission: ['coach', 'report'],
    },
    // {
    //   path: 'report/trialMember',
    //   component: () => import('views/coach/reports/TrialMember'),
    //   name: 'coachReportsTrialMember',
    //   title: '新手卡转化统计',
    //   permission: ['coach', 'report'],
    // },
    {
      path: 'report/conversion_statistics',
      component: () => import('views/coach/reports/ConversionStatistics'),
      name: 'coachReportsConversionStatistics',
      title: '私教转化统计',
      permission: ['coach', 'report'],
    },
    {
      path: 'coach/add',
      component: () => import('views/coach/Add'),
      name: 'coachAdd',
      title: '添加新教练',
      permission: ['coach'],
    },
    {
      path: ':coachId',
      redirect: '/coaches/:coachId/courses',
      component: () => import('views/coach/Detail'),
      name: 'coachDetail',
      title: '教练详情',
      permission: ['coach'],
      children: [
        {
          path: 'courses',
          title: '排课详情',
          component: () => import('views/coach/detail/CoursesArrangement'),
          name: 'coachDetailCoursesArrangement',
          children: [
            {
              path: 'elite_training',
              component: () => import('views/coach/record/EliteTraining'),
              name: 'coachRecordEliteTraining',
              title: '私教预约',
              root: true,
            },
            {
              path: 'group_training',
              component: () => import('views/coach/record/GroupTraining'),
              name: 'coachRecordGroupTraining',
              title: '团课预约',
              root: true,
            },
          ],
        },
        {
          path: 'members',
          component: () => import('views/coach/detail/MembersAmount'),
          name: 'coachDetailMembersAmount',
          title: '会员统计',
          children: [
            {
              path: 'elite_training_members',
              component: () => import('views/coach/record/EliteTrainingMembers'),
              name: 'coachRecordEliteTrainingMembers',
              title: '私教会员',
              root: true,
            },
            {
              path: 'service_members',
              component: () => import('views/coach/record/ServiceMembers'),
              name: 'coachRecordServiceMembers',
              title: '服务会员',
              root: true,
            },
            {
              path: 'trial_members',
              component: () => import('views/coach/record/TrialMembers'),
              name: 'coachRecordTrialMembers',
              title: '新手卡会员',
              root: true,
            },
          ],
        },
        {
          path: 'sales',
          component: () => import('views/coach/detail/CoursesSales'),
          name: 'coachDetailCoursesSales',
          title: '课程销售',
          children: [
            {
              path: 'elite_orders',
              component: () => import('views/coach/record/EliteOrders'),
              name: 'coachRecordEliteOrders',
              title: '私教销售',
              root: true,
            },
            {
              path: 'group_orders',
              component: () => import('views/coach/record/GroupOrders'),
              name: 'coachRecordGroupOrders',
              title: '团课销售',
              root: true,
            },
          ],
        },
        {
          path: 'audits',
          component: () => import('views/coach/audits/Detail'),
          name: 'coachAuditsDetail',
          title: '人事变更',
        },
        {
          path: 'workouts',
          component: () => import('views/coach/Workouts'),
          name: 'coachWorkouts',
          title: '可查看课程',
        },
      ],
    },
  ],
}
