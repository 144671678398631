import Layout from 'views/layout/Layout'

export default {
  path: '/workouts',
  redirect: '/workouts/list',
  component: Layout,
  title: '团课管理',
  name: 'workouts',
  permission: ['workout'],
  hidden: false,
  icon: 'grouptraining',
  children: [{
      path: 'list',
      name: 'WorkoutList',
      component: () => import('views/workout/List'),
      title: '团课课程',
      // icon: 'grouptraining',
      hidden: false,
    },
    {
      path: 'bgms',
      name: 'WorkoutBgms',
      component: () => import('views/bgms/List'),
      title: '团课音乐',
      keepAlive: true,
      permission: ['bgm'],
      hidden: false,
      children: [{
        path: 'add',
        name: 'WorkoutAddBgm',
        component: () => import('views/bgms/Add'),
        title: '添加团课音乐',
        root: true,
      }, ]
    },
    {
      path: 'training_modes',
      name: 'WorkoutTrainingModes',
      component: () => import('views/training_mode/List'),
      title: '训练模式',
      keepAlive: true,
      hidden: false,
      permission: ['workout', 'training_mode'],
      children: [{
          path: 'add',
          name: 'WorkoutAddTrainingMode',
          component: () => import('views/training_mode/Add'),
          title: '添加训练模式',
          root: true,
        },
        {
          path: ':trainingModeId/edit',
          name: 'WorkoutEditTrainingMode',
          component: () => import('views/training_mode/Edit'),
          title: '修改训练模式',
          root: true,
        },
      ]
    },

    {
      path: 'equipments',
      component: () => import('views/equipment/List'),
      name: 'equipment',
      keepAlive: true,
      permission: ['exercise_data'],
      title: '器械管理',
      hidden: false,
      children: [{
          path: 'list',
          name: 'EquipmentList',
          permission: ['exercise_data', 'exercise'],
          component: () => import('views/equipment/List'),
          title: '器械列表',
          keepAlive: true,
          hidden: false,
        },
        {
          path: 'add',
          name: 'EquipmentAdd',
          component: () => import('views/equipment/Add'),
          title: '添加器械',
          root: true,
        },
        {
          path: ':equipmentId/edit',
          name: 'EquipmentEdit',
          component: () => import('views/equipment/Edit'),
          title: '编辑器械',
          root: true,
        },
      ],
    },

    {
      path: ':workoutId',
      // redirect: '/workouts/:workoutId/packages',
      component: () => import('views/workout/Detail'),
      name: 'workoutDetail',
      title: '团课产品详情',
      children: [{
          path: 'workout_versions/:workoutVersionId/template_outline',
          name: 'templateOutline',
          component: () => import('views/workout/workout_versions/TemplateOutline'),
          title: '课程结构',
          root: true,
        },
        {
          path: 'workout_versions/:workoutVersionId/images',
          component: () => import('views/workout/workout_versions/images/List'),
          name: 'workoutVersionImagesList',
          title: '团课版本图片',
          root: true,
        },
        {
          path: 'workout_versions/:workoutVersionId/images/add',
          component: () => import('views/workout/workout_versions/images/Add'),
          name: 'workoutVersionAddImages',
          title: '添加团课版本图片',
          root: true,
        }
      ]
    },
    {
      path: 'workout_outlines',
      // component: () => import('views/workout_outline/Detail'),
      redirect: ':workoutOutlineId',
      name: 'workout_outlineDetail',
      title: '课程内容详情',
      children: [{
        path: ':workoutOutlineId',
        component: () => import('views/workout_outline/Detail'),
        name: 'workoutOutlineDetail',
        title: '课程内容详情',
        root: true,
        children: [{
            path: 'outline',
            component: () => import('views/workout_outline/outline/Outline'),
            name: 'workoutOutlineDetailOutline',
            title: '课程内容',
          },
          {
            path: 'timeline',
            component: () => import('views/workout_outline/timeline/Timeline'),
            name: 'workoutOutlineDetailTimeline',
            title: '课程时间线',
          },
          {
            path: 'default_playlist',
            component: () => import('views/workout_outline/default_playlist/DefaultPlaylist'),
            name: 'workoutOutlineDetailDefaultPlaylist',
            title: '课程默认歌单',
          },
        ],
      }, ],
    },

  ],
}
