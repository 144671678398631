import request from 'utils/request'

// 会员列表
export function fetchMembers(params) {
  return request({
    url: '/members',
    method: 'get',
    params,
  })
}

// 会员详情
export function fetchMember(memberId) {
  return request({
    url: '/members/' + memberId,
    method: 'get',
  })
}

export function fetchMemberTrainingProfile(memberId) {
  return request({
    url: '/members/' + memberId + '/training_profile',
    method: 'get',
  })
}

export function editMemberTrainingProfile(memberId, data) {
  return request({
    url: '/members/' + memberId + '/training_profile',
    method: 'put',
    data
  })
}

export function fetchFinancialOverview(memberId) {
  return request({
    url: '/members/' + memberId + '/financial_overview',
    method: 'get',
  })
}

// 会员卡余额
export function fetchMembershipsOverview(memberId, params) {
  return request({
    url: '/members/' + memberId + '/memberships/overview',
    method: 'get',
    params
  })
}

// 优惠券余额
export function fetchCouponsOverview(memberId) {
  return request({
    url: '/members/' + memberId + '/coupons/overview',
    method: 'get',
  })
}

export function fetchAccounts(memberId, params) {
  return request({
    url: '/members/' + memberId + '/accounts',
    method: 'get',
    params,
  })
}

export function fetchTrainingsOverview(memberId, params) {
  return request({
    url: `/members/${memberId}/trainings/overview`,
    method: 'get',
    params,
  })
}

export function fetchOrdersOverview(memberId, params) {
  return request({
    url: `/members/${memberId}/orders/overview`,
    method: 'get',
    params,
  })
}

export function fetchTrainings(memberId, params) {
  return request({
    url: `/members/${memberId}/trainings`,
    method: 'get',
    params,
  })
}

export function fetchOrders(memberId, params) {
  return request({
    url: `/members/${memberId}/orders`,
    method: 'get',
    params,
  })
}

export function addMember(data) {
  return request({
    url: '/members',
    method: 'post',
    data,
  })
}

export function editMember(memberId, data) {
  return request({
    url: '/members/' + memberId,
    method: 'put',
    data,
  })
}

// 优惠券列表
export function fetchCoupons(memberId, params) {
  return request({
    url: `/members/${memberId}/coupons`,
    method: 'get',
    params,
  })
}

// 会员卡训练账户列表
export function fetchMembershipAccounts(memberId, params) {
  return request({
    url: `/members/${memberId}/membership_accounts`,
    method: 'get',
    params,
  })
}

// 更新私教教练
export function updateMembershipAccountCoach(memberId, accountId, params) {
  return request({
    url: `/members/${memberId}/membership_accounts/${accountId}/coach`,
    method: 'put',
    params,
  })
}

// 会员卡使用记录
export function fetchMemberships(memberId, params) {
  return request({
    url: `/members/${memberId}/memberships`,
    method: 'get',
    params,
  })
}

// 沟通记录列表
export function fetchCommunications(memberId, params) {
  return request({
    url: `/members/${memberId}/communications`,
    method: 'get',
    params,
  })
}

// 添加沟通记录
export function addCommunications(memberId, data) {
  return request({
    url: `/members/${memberId}/communications`,
    method: 'post',
    data,
  })
}

// 预设Tag列表
export function fetchPresetTags(params) {
  return request({
    url: `/support/preset_tags`,
    method: 'get',
    params,
  })
}

// 添加预设Tag
export function addPresetTag(data) {
  return request({
    url: `/support/preset_tags`,
    method: 'post',
    data,
  })
}

// 删除预设Tag
export function deletePresetTag(id) {
  return request({
    url: `/support/preset_tags/${id}`,
    method: 'delete',
  })
}

// 批量权限延期
export function extendMembership(data) {
  return request({
    url: `/members/extend_membership`,
    method: 'post',
    data,
  })
}

// 导出电话列表
export function exportPhone(params) {
  return request({
    url: `/members/export_phone`,
    method: 'get',
    params,
  })
}

// 训练频次
export function fetchFrequencyReport(memberId, params) {
  return request({
    url: `/members/${memberId}/trainings/frequency_report`,
    method: 'get',
    params,
  })
}

// 消费频次
export function fetchOrderFrequencyReport(memberId, params) {
  return request({
    url: `/members/${memberId}/orders/frequency_report`,
    method: 'get',
    params,
  })
}

// 训练倾向
export function fetchTrainingsInclinationReport(memberId, params) {
  return request({
    url: `/members/${memberId}/trainings/inclination_report`,
    method: 'get',
    params,
  })
}

// 消费倾向
export function fetchOrderInclinationReport(memberId, params) {
  return request({
    url: `/members/${memberId}/orders/inclination_report`,
    method: 'get',
    params,
  })
}

// 私教新手会员卡列表
export function fetchEliteTrainingTrailAccounts(params) {
  return request({
    url: `/members/elite_training_trail_accounts`,
    method: 'get',
    params,
  })
}

// 共享卡成员列表
export function fetchAccountMembers(memberId, membershipAccountId, params) {
  return request({
    url: `/members/${memberId}/membership_accounts/${membershipAccountId}/members`,
    method: 'get',
    params,
  })
}

// 删除共享卡成员
export function deleteAccountMember(memberId, membershipAccountId, accountMemberId) {
  return request({
    url: `/members/${memberId}/membership_accounts/${membershipAccountId}/members/${accountMemberId}`,
    method: 'delete',
  })
}

// 添加共享卡成员
export function addAccountMember(memberId, membershipAccountId, data) {
  return request({
    url: `/members/${memberId}/membership_accounts/${membershipAccountId}/members`,
    method: 'post',
    data,
  })
}

// 获取共享卡邀请二维码
export function fetchInviteQrCode(memberId, membershipAccountId) {
  return request({
    url: `/members/${memberId}/membership_accounts/${membershipAccountId}/invite_qr_code`,
    method: 'get',
  })
}

// 删除会员（不能作用于正式环境）
export function deleteMember(memberId) {
  return request({
    url: `/members/${memberId}`,
    method: 'delete',
  })
}

// 沟通记录
export function fetchCommunication(params) {
  return request({
    url: `/operation/communications`,
    method: 'get',
    params,
  })
}

// 沟通记录
export function fetchCommunicationAdmins() {
  return request({
    url: `/operation/communications/admins`,
    method: 'get',
  })
}

// 暂停会员卡
export function pauseMembership(memberId, membershipAccountId, data) {
  return request({
    url: `/members/${memberId}/membership_accounts/${membershipAccountId}/pause`,
    method: 'put',
    data,
  })
}

// 恢复会员卡
export function resumeMembership(memberId, membershipAccountId) {
  return request({
    url: `/members/${memberId}/membership_accounts/${membershipAccountId}/resume`,
    method: 'put',
  })
}

// 转移权限
export function memberTransfer(memberId, membershipAccountId, data) {
  return request({
    url: `/members/${memberId}/membership_accounts/${membershipAccountId}/transfer`,
    method: 'post',
    data,
  })
}

// kol图片上传
export function uploadKolImages(memberId, data) {
  return request({
    url: `/members/${memberId}/images`,
    method: 'post',
    data,
  })
}

// kol图片删除
export function deleteKolImages(memberId, imageId) {
  return request({
    url: `/members/${memberId}/images/${imageId}`,
    method: 'delete',
  })
}

export function fetchMemberService(params) {
  return request({
    url: '/tags/member_service',
    method: 'get',
    params,
  })
}

export function prolongMembership(memberId, membershipAccountId, data) {
  return request({
    url: `members/${memberId}/membership_accounts/${membershipAccountId}/prolong`,
    method: 'post',
    data,
  })
}

export function recoveryMembership(memberId, membershipAccountId, data) {
  return request({
    url: `members/${memberId}/membership_accounts/${membershipAccountId}/recovery`,
    method: 'post',
    data,
  })
}