import request from 'utils/request'

export function fetchCoaches(params) {
  return request({
    url: '/coaches',
    method: 'get',
    params
  })
}

export function fetchServiceCoaches(params) {
  return request({
    url: '/coaches/service_coach',
    method: 'get',
    params
  })
}

export function fetchCoach(coachId) {
  return request({
    url: '/coaches/' + coachId,
    method: 'get'
  })
}

export function addCoach(data) {
  return request({
    url: '/coaches',
    method: 'post',
    data,
  })
}

export function editCoach(coachId, data) {
  return request({
    url: `/coaches/${coachId}`,
    method: 'put',
    data,
  })
}

// 排课
export function fetchCoursesOverview(coachId, params) {
  return request({
    url: '/coaches/' + coachId + '/courses/overview',
    method: 'get',
    params,
  })
}

// 私教剩余概览
export function fetchEliteOverview(coachId) {
  return request({
    url: `/coaches/${coachId}/elite_overview`,
    method: 'get',
  })
}

// 教练课时统计
export function fetchCourseReport(coachId, params) {
  return request({
    url: `/coaches/${coachId}/courses/report`,
    method: 'get',
    params,
  })
}

// 教练私教会员概览
export function fetchEliteTraningMembersOverview(coachId, params) {
  return request({
    url: '/coaches/' + coachId + '/elite_training_members/overview',
    method: 'get',
    params,
  })
}

// 教练服务会员概览
export function fetchServiceMembersOverview(coachId, params) {
  return request({
    url: '/coaches/' + coachId + '/service_members/overview',
    method: 'get',
    params,
  })
}

// 新手卡会员概览
export function fetchTrialMembersOverview(coachId, params) {
  return request({
    url: '/coaches/' + coachId + '/trial_members/overview',
    method: 'get',
    params,
  })
}

// 教练销售概览
export function fetchSalesOverview(coachId, params) {
  return request({
    url: '/coaches/' + coachId + '/sales/overview',
    method: 'get',
    params,
  })
}

export function fetchAvailableTimeSlots(coachId, from, to) {
  return request({
    url: '/coaches/' + coachId + '/courses/available_time_slots',
    params: {
      from: from,
      to: to
    },
    method: 'get'
  })
}

export function fetchCourses(coachId, params) {
  return request({
    url: `/coaches/${coachId}/courses`,
    method: 'get',
    params,
  })
}

export function fetchSalesReport(coachId, params) {
  return request({
    url: '/coaches/' + coachId + '/sales/report',
    method: 'get',
    params,
  })
}

export function fetchConversionTrend(coachId, params) {
  return request({
    url: '/coaches/' + coachId + '/trial_members/conversion_trend',
    method: 'get',
    params,
  })
}

// 教练等级列表
export function fetchCoachLevels(params) {
  return request({
    url: '/coach/levels',
    method: 'get',
    params,
  })
}

export function fetchCoachLevelRecords(coachId) {
  return request({
    url: `/coaches/${coachId}/levels`,
    method: 'get',
  })
}

export function fetchStudios() {
  return request({
    url: '/studios',
    method: 'get',
  })
}


export function fetchOrders(coachId, params) {
  return request({
    url: `/coaches/${coachId}/orders`,
    method: 'get',
    params,
  })
}

export function fetchEliteTrainingMember(coachId, params) {
  return request({
    url: `/coaches/${coachId}/elite_training_members`,
    method: 'get',
    params,
  })
}

export function fetchServiceMember(coachId, params) {
  return request({
    url: `/coaches/${coachId}/service_members`,
    method: 'get',
    params,
  })
}

export function fetchTrialMember(coachId, params) {
  return request({
    url: `/coaches/${coachId}/trial_members`,
    method: 'get',
    params,
  })
}

export function exportReport(params) {
  return request({
    url: `/coach/report/export`,
    responseType: 'blob',
    method: 'get',
    params,
  })
}

export function fetchDailySchedule(params) {
  return request({
    url: `/coach/courses/daily_schedule`,
    method: 'get',
    params,
  })
}

// 教练人事变更记录
export function fetchAudits(coachId) {
  return request({
    url: `/coaches/${coachId}/audits`,
    method: 'get',
  })
}

// 教练转正
export function passProbation(coachId, data) {
  return request({
    url: `/coaches/${coachId}/pass_probation`,
    method: 'put',
    data,
  })
}

// 教练私教产品
export function fetchEliteTrainingProducts(coachId) {
  return request({
    url: `/coaches/${coachId}/elite_training_products`,
    method: 'get',
  })
}

// 转店
export function studioTransfer(coachId, data) {
  return request({
    url: `/coaches/${coachId}/studio_transfer`,
    method: 'put',
    data,
  })
}

// 转岗、晋升
export function levelTransfer(coachId, data) {
  return request({
    url: `/coaches/${coachId}/level_transfer`,
    method: 'put',
    data,
  })
}

// 离职
export function dismiss(coachId) {
  return request({
    url: `/coaches/${coachId}/dismiss`,
    method: 'put',
  })
}

// 分配会员
export function memberTransfer(coachId, data) {
  return request({
    url: `/coaches/${coachId}/member_transfer`,
    method: 'put',
    data,
  })
}

// 教练人事变更对应的会员变更统计
export function auditMembersOverview(coachId, auditId, params) {
  return request({
    url: `/coaches/${coachId}/audits/${auditId}/members_overview`,
    method: 'get',
    params,
  })
}

// 教练人事变更对应的会员变更
export function auditMembers(coachId, auditId, params) {
  return request({
    url: `/coaches/${coachId}/audits/${auditId}/members`,
    method: 'get',
    params,
  })
}

// 私教产品列表
export function fetchEliteTrainingProductsList(coachId) {
  return request({
    url: `/coach/elite_training_products`,
    method: 'get',
  })
}

// 教练私教产品
export function fetchEliteTrainingProductsCoachList(productId) {
  return request({
    url: `/coach/elite_training_products/${productId}/coaches`,
    method: 'get',
  })
}

// 添加教练私教产品
export function eliteTrainingProductsAdd(productId, data) {
  return request({
    url: `/coach/elite_training_products/${productId}/coaches`,
    method: 'post',
    data,
  })
}

// 删除教练私教产品
export function eliteTrainingProductsRemove(productId, coachId) {
  return request({
    url: `/coach/elite_training_products/${productId}/coaches/${coachId}`,
    method: 'delete',
  })
}

// 教练等级类型
export function fetchCoachCategories() {
  return request({
    url: `/coach/level/categories`,
    method: 'get',
  })
}

// 添加教练等级
export function addLevels(data) {
  return request({
    url: `/coach/levels`,
    method: 'post',
    data,
  })
}

// 删除教练等级
export function deleteLevels(levelId) {
  return request({
    url: `/coach/levels/${levelId}`,
    method: 'delete',
  })
}

// 教练等级详情
export function fetchCoachLevel(id) {
  return request({
    url: `/coach/levels/${id}`,
    method: 'get',
  })
}

// 修改教练等级
export function editCoachLevel(id, data) {
  return request({
    url: `/coach/levels/${id}`,
    method: 'put',
    data,
  })
}

// 教练等级详情
export function coachLevelMoveUp(id) {
  return request({
    url: `/coach/levels/${id}/move_up`,
    method: 'put',
  })
}

// 晋升信息
export function fetchPromotion(coachId) {
  return request({
    url: `/coaches/${coachId}/promotion`,
    method: 'get',
  })
}

// 激活教练
export function coachActive(coachId) {
  return request({
    url: `/coaches/${coachId}/active`,
    method: 'post',
  })
}

// 暂停教练
export function coachPause(coachId) {
  return request({
    url: `/coaches/${coachId}/active`,
    method: 'delete',
  })
}

// 私教耗课统计
export function fetchEliteMemberConsumptions(coachId, params) {
  return request({
    url: `/coaches/${coachId}/elite_training_members/consumptions`,
    method: 'get',
    params,
  })
}

// 私教耗课人次统计总览
export function fetchConsumptionsOverview(params) {
  return request({
    url: `/coach/report/elite_training_consumptions_overview`,
    method: 'get',
    params,
  })
}

// 私教耗课人次按教练统计
export function fetchEliteTrainingConsumptions(params) {
  return request({
    url: `/coach/report/elite_training_consumptions`,
    method: 'get',
    params,
  })
}

// 私教会员转化率统计
export function fetchEliteTrainingConversion(params) {
  return request({
    url: `/coach/report/elite_training_conversion`,
    method: 'get',
    params,
  })
}

// 私教会员转化率
export function fetchEliteTrainingConversionOverview(params) {
  return request({
    url: `/coach/report/elite_training_conversion_overview`,
    method: 'get',
    params,
  })
}
