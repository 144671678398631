import Layout from 'views/layout/Layout'
import { get } from "lodash";

export default {
  path: '/courses',
  redirect: '/courses/scheduler',
  component: Layout,
  name: 'courses',
  title: '排课管理',
  hidden: false,
  icon: 'calendar',
  permission: ['training_schedule'],
  children: [
    {
      path: 'scheduler',
      component: () => import('views/courses/scheduler/Courses'),
      name: 'coursesScheduler',
      title: '排课管理',
      permission: ['training_schedule'],
      hidden: false,
    },
    {
      path: 'new',
      component: () => import('views/courses/scheduler/New'),
      name: 'coursesNew',
      permission: ['training_schedule'],
      title: '添加课程',
    },
  ]
}
