import list from "./list"
import record from "./record"
import fetch from "./fetch"
import dialog from "./dialog"
import commonForm from "./common_form"

export {
  list,
  record,
  fetch,
  dialog,
  commonForm,
}
