import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import '@/permission'

import { get, random } from 'lodash'
import { tap } from '@/utils/'
Vue.prototype.$get = get
Vue.prototype.$tap = tap
Vue.prototype.$randomKey = (s = '') => s + 'random_key_' + Date.now()
Vue.prototype.$openDialog = name => store.commit({type: 'OPEN_DIALOG', name})
Vue.prototype.$closeDialog = _ => store.commit('CLOSE_DIALOG')

import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI)

// 注册全局组件
import registerComponent from '../components/'
registerComponent(Vue)

// directive
import { permission, affix } from '../directive/'
Vue.directive('permission', permission)
Vue.directive('affix', affix)

// event bus
window.eventBus = new Vue()

//config moment
// import VueMoment from "vue-moment";
// Vue.use(VueMoment);

import 'fullcalendar/dist/fullcalendar.css';
// import FullCalendar from 'vue-full-calendar';
// Vue.use(FullCalendar)

// quill

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

let toolbarOptions = [
  ['bold', 'strike'], // toggled buttons

  [{
    'list': 'ordered'
  }, {
    'list': 'bullet'
  }],
  [{
    'indent': '-1'
  }, {
    'indent': '+1'
  }], // outdent/indent

  ['link', 'image']
];

Vue.use(VueQuillEditor,  {
  modules: {
    toolbar: toolbarOptions
  },
  placeholder: '请输入文本...'
})

// 注册公共组件
// import {ButtonGroup} from '@/components/filter/'
// Vue.component('button-group', ButtonGroup)

// global styles
import 'normalize.css/normalize.css'
import 'styles/index.scss'

import App from '@/views/App'

router.beforeEach((to, from, next) => { // 清空 dialog 状态
  store.commit('CLOSE_DIALOG')
  next()
})

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
  })
})
