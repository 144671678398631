import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'

export default {
  path: '/academy',
  redirect: '/academy/tutorials/group_training/list',
  name: 'academy',
  component: Layout,
  title: '教练培训',
  hidden: false,
  icon: 'sct',
  permission: ['tutorial'],
  children: [
    // {
    //   path: 'tutorials/group_training',
    //   component: RouterView,
    //   redirect: '/academy/tutorials/group_training/list',
    //   name: 'academyGroupTrining',
    //   title: '团课基础',
    //   hidden: false,
    //   permission: ['tutorial', 'view_group_training_tutorial'],
    //   children: [
    //     {
    //       path: 'list',
    //       component: () => import('views/academy/tutorials/List'),
    //       name: 'academyGroupTrainingList',
    //       title: '团课基础列表',
    //     },
    //     {
    //       path: ':tutorialId/detail',
    //       component: () => import('views/academy/tutorials/Detail'),
    //       name: 'academyGroupTrainingDetail',
    //       title: '课程培训',
    //     },
    //   ]
    // },
    {
      path: 'tutorials/elite_training',
      component: RouterView,
      redirect: '/academy/tutorials/elite_training/list',
      name: 'academyEliteTraining',
      title: '私教基础',
      hidden: false,
      permission: ['tutorial', 'view_elite_training_tutorial'],
      children: [
        {
          path: 'list',
          component: () => import('views/academy/tutorials/List'),
          name: 'academyEliteTrainingList',
          title: '私教基础列表',
        },
        {
          path: ':tutorialId/detail',
          component: () => import('views/academy/tutorials/Detail'),
          name: 'academyEliteTrainingDetail',
          title: '课程培训',
        },
      ]
    },
    {
      path: 'tutorials/new',
      component: () => import('views/academy/tutorials/New'),
      name: 'academyNew',
      title: '添加新文档',
      permission: ['tutorial'],
    },
    {
      path: 'tutorials/:tutorialId/edit',
      component: () => import('views/academy/tutorials/Edit'),
      name: 'academyEdit',
      title: '编辑文档',
      permission: ['tutorial'],
    },
    {
      path: 'tutorials/:tutorialId/attachment/edit',
      component: () => import('views/academy/tutorials/AttachmentEdit'),
      name: 'academyAttachmentEdit',
      title: '添加附件',
      permission: ['tutorial'],
    },
    {
      path: 'workout_outlines/:workoutOutlineId/attachment/edit',
      component: () => import('views/academy/component/VideoEdit'),
      name: 'versionVideoEdit',
      title: '添加附件',
      permission: ['tutorial'],
    },
    {
      path: 'workouts',
      component: () => import('views/academy/workouts/List'),
      name: 'academyWorkouts',
      title: '团课产品',
      hidden: false,
      permission: ['tutorial'],
    },
    {
      path: 'workouts/:woId/detail',
      component: () => import('views/academy/workouts/Detail'),
      name: 'academyWorkoutsDetail',
      title: '团课产品详情',
      permission: ['tutorial'],
    },
  ],
}
