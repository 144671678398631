import request from 'utils/request'

export function login(email, password) {
  return request({
    url: '../users/sign_in',
    method: 'post',
    data: {
      user: {
        email,
        password
      }
    }
  })
}

export function logout() {
  return request({
    url: '../users/sign_out',
    method: 'delete'
  })
}


export function fetchProfile(token) {
  return request({
    url: '/user',
    method: 'get',
    params: { token }
  })
}
