import Layout from 'views/layout/Layout'

export default {
  path: '/pride',
  redirect: '/pride/list',
  name: 'pride',
  component: Layout,
  title: '优享服务',
  permission: ['prime'],
  hidden: false,
  icon: 'member',
  children: [
    {
      path: 'training_plan',
      title: '训练计划管理',
      component: () => import('views/pride/training_plan/Index'),
      name: 'prideTrainingPlan',
      hidden: false,
    },
    {
      path: 'training_plan/add',
      title: '添加训练计划',
      component: () => import('views/pride/training_plan/Add'),
      name: 'prideTrainingPlanAdd',
    },
    {
      path: 'training_plan/:trainingPlanId/edit',
      title: '编辑训练计划',
      component: () => import('views/pride/training_plan/Edit'),
      name: 'prideTrainingPlanEdit',
    },
    {
      path: 'training_plan/:trainingPlanId/detail',
      title: '训练计划详情',
      component: () => import('views/pride/training_plan/Detail'),
      name: 'prideTrainingPlanEdit',
    },
    {
      path: 'eating_plan',
      title: '饮食计划管理',
      component: () => import('views/pride/eating_plan/Index'),
      name: 'prideEatingPlan',
      hidden: false,
    },
    {
      path: 'eating_plan/add',
      title: '添加饮食计划',
      component: () => import('views/pride/eating_plan/Add'),
      name: 'prideEatingPlanAdd',
    },
    {
      path: 'eating_plan/:eatingPlanId/edit',
      title: '编辑饮食计划',
      component: () => import('views/pride/eating_plan/Edit'),
      name: 'prideTEatinglanEdit',
    },
    {
      path: 'list',
      title: '优享会员管理',
      component: () => import('views/pride/members/Index'),
      name: 'prideMembersList',
      hidden: false,
    },
    {
      path: ':prideAccountId/detail',
      title: '优享会员详情',
      component: () => import('views/pride/members/Detail'),
      name: 'prideMemberDetail',
    },
    {
      path: 'fitness_test_level',
      title: '体能测试等级管理',
      component: () => import('views/pride/fitness_test_level/Index'),
      name: 'prideFitnessTestLevel',
      hidden: false,
      permission: ['prime', 'fitness_test_level'],
    },
    {
      path: 'fitness_test_level/add',
      title: '添加体能测试等级',
      component: () => import('views/pride/fitness_test_level/Add'),
      name: 'prideFitnessTestLevelAdd',
    },
    {
      path: 'fitness_test_level/:fitnessTestLevelId/edit',
      title: '编辑体能测试等级',
      component: () => import('views/pride/fitness_test_level/Edit'),
      name: 'prideFitnessTestLevelEdit',
    },
  ],
}
