import Layout from 'views/layout/Layout'

export default {
  path: '/report',
  redirect: '/report/training',
  name: 'report',
  component: Layout,
  title: '运营数据',
  hidden: false,
  icon: 'statistics',
  permission: ['statistics'],
  children: [
    {
      path: 'training',
      component: () => import('views/report/Training'),
      name: 'reportTraining',
      title: '训练数据',
      permission: ['statistics', 'trainings'],
      hidden: false,
    },
    {
      path: 'sales',
      component: () => import('views/report/Sale'),
      name: 'reportSale',
      title: '销售数据',
      permission: ['statistics', 'finance'],
      hidden: false,
    },
    {
      path: 'member_distribution',
      component: () => import('views/report/MemberDistribution'),
      name: 'reportMemberDistribution',
      title: '用户画像',
      permission: ['statistics', 'member_profile'],
      hidden: false,
    },
    {
      path: 'occupancy_trends',
      component: () => import('views/report/OccupancyTrends'),
      name: 'reportOccupancyTrends',
      title: '上座率趋势',
      permission: ['statistics', 'occupancy_rate'],
      hidden: false,
    },
  ],
}
