import { PAGINATION_INFO } from '@/constant/entries'
import { filterFalsy } from '@/utils/'
import { ButtonGroup, Search } from '@/components/filter'

// 用于路由顶层的列表页
export default {
  components: {
    ButtonGroup,
    Search,
  },

  data: function () {
    return {
      isLoading: true,
      total: null,
      sourceData: null,
      autoFetch: true,
      tableAutoFetch: true,
      filter: {
        page: 1,
        pageSize: PAGINATION_INFO.pageSize,
      },
    }
  },

  computed: {
    rootPath() {
      let path = this.$route.matched[0].path
      return path
    },

    propsData() {
      let result = {
        data: this.sourceData,
        columnsConfig: this.columnsConfig,
        total: this.total,
        page: this.filter.page,
        pageSize: this.filter.pageSize,
        isLoading: this.isLoading,
      }
      return result
    },
  },

  watch: {
    filter: {
      handler: function () {
        if (this.tableAutoFetch) {
          let params = this.makeParams()
          this.fetchData(params)
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.autoFetch) {
      let params = this.makeParams()
      this.fetchData(params)
    }
  },

  methods: {
    fetchData(params) {
      this.isLoading = true
      if (!params) {
        let { page, pageSize } = this.filter
        params = { page, pageSize }
      }
      if (!this.fetchApi) {
        return
      }
      this.fetchApi(params).then(res => {
        if (res) {
          this.sourceData = this.formatData(res.data)
          this.isLoading = false
          this.total = parseInt(res.headers.total, 10)
        } else {
          this.isLoading = true
        }
      })
    },

    formatData(data) {
      return data
    },

    makeParams() {
      let params = filterFalsy(this.filter)
      return params
    },

    getDetailPath(id) {
      let path = this.rootPath
      return `${path}/${id}`
    },

    changePage(value) {
      this.filter.page = value
    },

    reload() {
      let params = this.makeParams()
      this.fetchData(params)
    },

    openDialog(name) {
      this.$store.commit({
        type: 'OPEN_DIALOG',
        name,
      })
    },

    closeDialog() {
      this.$store.commit('CLOSE_DIALOG')
    },
  },
}
