import Layout from 'views/layout/Layout'

export default {
  path: '/exercises',
  // redirect: '/exercises/list',
  name: 'exercises',
  permission: ['exercise_data'],
  component: Layout,
  title: '动作数据库',
  hidden: false,
  icon: 'motion',
  children: [
    {
      path: 'list',
      name: 'ExerciseList',
      permission: ['exercise_data', 'exercise'],
      component: () => import('views/exercise/exercise/List'),
      title: '动作数据',
      keepAlive: true,
      hidden: false,
    },
    {
      path: 'filming_exercise_list',
      name: 'FilmingExerciseList',
      permission: ['exercise_data', 'filming_exercises'],
      component: () => import('views/exercise/filming_exercise/List'),
      title: '待录制动作',
      keepAlive: true,
      hidden: false,
    },
    {
      path: 'overview',
      name: 'ExerciseOverview',
      component: () => import('views/exercise/exercise/Overview'),
      title: '动作统计',
    },
    {
      path: ':exerciseId/edit',
      name: 'ExerciseEdit',
      component: () => import('views/exercise/exercise/Edit'),
      title: '动作编辑',
    },
    // {
    //   path: ':exerciseId',
    //   redirect: '/exercises/:exerciseId/dashboard',
    //   component: () => import('views/exercise/Detail'),
    //   title: '动作详情',
    //   children: [
    //     {
    //       path: 'dashboard',
    //       component: () => import('views/exercise/detail/Dashboard'),
    //       title: '动作信息',
    //     },
    //   ],
    // },
  ],
}
