import request from 'utils/request'


// 修改账号密码
export function editPassword(data) {
  return request({
    url: '/admins/password',
    method: 'put',
    data,
  })
}


// 门禁系统开门记录
export function fetchAccessLogs(params) {
  return request({
    url: '/gate/access_logs',
    method: 'get',
    params,
  })
}

// 门禁系统白名单列表
export function fetchWhiteLists(params) {
  return request({
    url: '/gate/whitelists',
    method: 'get',
    params,
  })
}

// 添加门禁系统白名单
export function addWhiteList(data) {
  return request({
    url: '/gate/whitelists',
    method: 'post',
    data,
  })
}

// 删除门禁系统白名单
export function deleteWhiteList(whiteListId, data) {
  return request({
    url: `/gate/whitelists/${whiteListId}`,
    method: 'delete',
    data,
  })
}

// 打开门禁
export function openGate(studioId) {
  return request({
    url: `/gate/${studioId}/open`,
    method: 'get',
  })
}

// 短信列表
export function fetchMessages(params) {
  return request({
    url: '/messages',
    method: 'get',
    params,
  })
}

// 权限管理
export function fetchPermissions(params) {
  return request({
    url: '/permission/permissions',
    method: 'get',
    params,
  })
}

// 权限组列表
export function fetchRoles(params) {
  return request({
    url: '/permission/roles',
    method: 'get',
    params,
  })
}

// 新建权限组
export function addRole(data) {
  return request({
    url: '/permission/roles',
    method: 'post',
    data,
  })
}

// 权限组详情
export function fetchRole(roleId) {
  return request({
    url: `/permission/roles/${roleId}`,
    method: 'get',
  })
}

// 修改权限组
export function editRole(roleId, data) {
  return request({
    url: `/permission/roles/${roleId}`,
    method: 'put',
    data,
  })
}

// 上移权限组
export function moveUp(roleId) {
  return request({
    url: `/permission/roles/${roleId}/move_up`,
    method: 'put',
  })
}

// 后台帐号列表
export function fetchAdmins(params) {
  return request({
    url: '/admins',
    method: 'get',
    params,
  })
}

// 获取后台帐号
export function fetchAdmin(adminId) {
  return request({
    url: `/admins/${adminId}`,
    method: 'get',
  })
}

// 添加后台帐号
export function addAdmin(data) {
  return request({
    url: '/admins',
    method: 'post',
    data,
  })
}

// 修改后台帐号
export function editAdmin(adminId, data) {
  return request({
    url: `/admins/${adminId}`,
    method: 'put',
    data,
  })
}

// 激活后台帐号
export function activateAdmin(adminId) {
  return request({
    url: `/admins/${adminId}/active`,
    method: 'post',
    params,
  })
}

// 删除后台帐号
export function deleteAdmin(adminId) {
  return request({
    url: `/admins/${adminId}/active`,
    method: 'delete',
  })
}
