<template>
  <el-select 
    v-model="coachId" 
    :placeholder="placeholder" 
    size='small'
    class='item'
    remote
    filterable
    clearable
    :remote-method="fetchFilterData"
    :loading='isLoading'
    v-loading='firstLoading'
  >
    <el-option
      v-for="(item) in options"
      :key="item.id"
      :label="item.label"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { fetchCoaches, fetchCoach, fetchServiceCoaches } from '@/api/coach'

export default {
  name: 'MfCoach',
  
  props: {
    value: null,
    category: null,
    levelId: Number,
    type: {
      type: String,
      default: 'normal',
    },
    placeholder: {
      type: String,
      default: '输入教练名称进行搜索',
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      coachId: this.value,
      options: null,
      isLoading: true,
      firstLoading: true,
    }
  },
  
  computed: {
    filter() {
      return {
        active: true,
        version: 'essential',
        category: this.category,
        levelId: this.levelId,
      }
    },
  },
  
  watch: {
    value(value) {
      this.coachId = value
    },

    coachId() {
      this.change()
    },

    filter: {
      handler: function() {
        this.fetchFilterData()
      },
      deep: true,
    },
  },
  
  async created() {
    await this.fetchFilterData()
    this.firstLoading = false
  },
  
  methods: {
    async fetchFilterData(query) {
      const fetchApi = this.type === 'normal' ? fetchCoaches : fetchServiceCoaches
      this.isLoading = true

      if (this.value && !query) {
        await fetchCoach(this.value).then(res => {
          this.options = this.formatData([res.data])
        })
      } else {
        let params = {
          ...this.filter,
          query,
        }
        await fetchApi(params).then(res => {
          this.options = this.formatData(res.data)
        })
      }

      this.isLoading = false
    },

    formatData(arr) {
      return arr.map(o => ({...o, label: `${o.nickname}（${o.name}）`}))
    },

    change() {
      this.$emit('change', this.coachId)
      this.$emit('getObjByChange', this.options.filter(o => o.id === this.coachId)[0])
    }
  },
}
</script>

<style lang='scss' scoped>
</style>