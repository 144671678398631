import Layout from 'views/layout/Layout'
import RouterView from 'views/layout/RouterView'
import { cloneDeep } from "lodash";

let routerView = cloneDeep(RouterView)

export default {
  path: '/studios',
  redirect: '/studios/list',
  name: 'studios',
  component: Layout,
  title: '门店列表',
  hidden: false,
  icon: 'store',
  permission: ['studio'],
  children: [
    {
      path: 'list',
      component: () => import('views/studios/List'),
      name: 'studiosList',
      title: '门店列表',
      icon: 'store',
      hidden: false,
      permission: ['studio', 'studio'],
    },
    {
      path: 'add',
      component: () => import('views/studios/Add'),
      name: 'studioAdd',
      title: '新增门店',
      permission: ['studio', 'studio'],
    },
    {
      path: ':studioId',
      redirect: { name: 'studioDetail' },
      component: routerView,
      name: 'studioStudioId',
      title: '门店管理',
      permission: ['studio', 'studio'],
      children: [
        {
          path: 'detail',
          component: () => import('views/studios/Detail'),
          name: 'studioDetail',
          title: '门店详情',
        },
        {
          path: 'edit',
          component: () => import('views/studios/Edit'),
          name: 'studioEdit',
          title: '编辑门店',
        },
        {
          path: 'sensors/add',
          component: () => import('views/studios/sensors/Add'),
          name: 'studioSensorAdd',
          title: '添加心率设备',
        },
        {
          path: 'sensors/:sensorId/edit',
          component: () => import('views/studios/sensors/Edit'),
          name: 'studioSensorEdit',
          title: '编辑心率设备',
        },
        {
          path: 'account',
          component: () => import('views/studios/components/EditAccount'),
          name: 'studioEditAccount',
          title: '编辑账号详情',
        },
        {
          path: 'pictures/add',
          component: () => import('views/studios/pictures/Add'),
          name: 'studioAddPictures',
          title: '添加场地图片',
        },
        {
          path: 'zones/add',
          component: () => import('views/studios/zones/Add'),
          name: 'studioAddZone',
          title: '添加训练场地信息',
        },
        {
          path: 'zones/:zoneId/edit',
          component: () => import('views/studios/zones/Edit'),
          name: 'studioEditZone',
          title: '添加训练场地信息',
        },
      ],
    },
  ],
}
