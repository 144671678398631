<template>
  <el-select 
    v-model="current" 
    placeholder="产品选择"
    size='small'
    class="item"
    v-loading='isLoading'
    :multiple="multiple"
  >
    <el-option
      v-for="item in options"
      :key="item.name"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
</template>

<script>
import { fetchWorkouts } from '@/api/workout'
import fetch from '@/mixins/fetch'

export default {
  name: 'MfWorkout',
  
  mixins: [fetch],
   
  props: {
    value: null,
    all: {
      type: Boolean,
    },
    defaultValueIndex: {
      type: Number,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: function() {
        return {
          active: true,
        }
      }
    },
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      current: this.value,
      fetchApi: _ => fetchWorkouts(this.params),
    }
  },

  computed: {
    options() {
      if (!this.sourceData) {
        return
      }
      let result = this.sourceData
      if (this.all) {
        result = [{
          id: null,
          name: '全部',
        }, ...this.sourceData]
      }
      return result
    },
  },
  
  watch: {
    current(val) {
      this.change(val)
    },

    value(val) {
      if (this.current !== val) {
        this.current = val
      }
    },
  },
  
  methods: {
    change(value) {
      this.$emit('change', value)
      this.$emit('changeData', this.sourceData.find(item => item.id === value))
    },

    fetchCb(res) {
      let index = this.defaultValueIndex
      if (index && !this.value) {
        this.current = res.data[index - 1].id
      }      
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
