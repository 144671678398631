import Layout from 'views/layout/Layout';

export default {
  path: '/member_operation',
  redirect: '/members',
  name: 'member_operation',
  component: Layout,
  title: '用户运营',
  //TODO: add permission
  permission: ['member'],
  hidden: false,
  icon: 'member',
  children: [
    {
      path: 'members',
      component: () => import('views/member_operation/Members'),
      title: '用户列表',
      keepAlive: true,
      name: 'MemberOperationMembers',
      //TODO: add permission
      permission: ['member', 'member'],
      hidden: false
    },
    {
      path: 'bookings',
      component: () => import('views/member_operation/Bookings'),
      title: '用户到店',
      keepAlive: true,
      name: 'MemberOperationBookings',
      //TODO: add permission
      permission: ['member', 'member'],
      hidden: false
    },
  ],
};
