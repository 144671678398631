<template>
  <div class="mf-passowrd-edit">
    <el-dialog
      title='修改密码'
      :visible='dialogVisible'
      @close='handleClose'
      class="dialog-edit"
    >
      <el-form
        ref="form" 
        :model="form" 
        label-width="140px"
        class="el-input--small"
      >
        <el-form-item label='新密码' prop='password'>
          <el-input v-model='form.password' type="password"></el-input>
        </el-form-item>

        <el-form-item label='确认新密码' prop='passwordConfirmation'>
          <el-input v-model='form.passwordConfirmation' type="password"></el-input>
        </el-form-item>
                
        <el-button type='primary' @click='submitForm' class="btn-submit">保存</el-button>
      </el-form>
    </el-dialog>
  </div>  
</template>

<script>

import { editPassword } from '@/api/setting'
export default {
  name: 'editPassword',

  data() {
    return {
      form: {
        password: "",
        passwordConfirmation: ""
      }
    }
  },

  computed: {
    dialogVisible() {
      let {name} = this.$options
      return this.$store.getters.dialogVisible(name)
    },
  },

  methods: {
    submitForm() {
      editPassword(this.form).then(res => {
        this.$message({
          message: '修改密码成功',
          type: 'success',
        })
        this.handleClose();
      }).catch((err) => {
        this.$message({
          message: err,
          type: 'error',
        })
      })
    },

    handleClose() {
      this.$emit('close', false)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
