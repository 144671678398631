<template>
  <el-select 
    :value="id"
    placeholder="选择权限组"
    size='small'
    class="item"
    remote
    filterable
    :remote-method="fetchData"
    :loading='isLoading'
    v-loading='!firstLoaded'
    @change='handleChange'
  >
    <el-option
      v-for="item in sourceData"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>  
</template>

<script>
import { fetchRoles, fetchRole } from '@/api/setting'
import fetch from '@/mixins/fetch'

export default {
  name: 'MfRoles',

  model: {
    prop: 'id',
    event: 'change',
  },
  
  props: {
    id: null,
  },
  
  data() {
    return {
      sourceData: null,
      isLoading: true,
      firstLoaded: false,
    }
  },
  
  created() {
    this.fetchData()
  },
  
  methods: {
    async fetchData(query) {
      this.isLoading = true
      let fetchAll = fetchRoles({query})
      let fetchOne = this.id ? fetchRole(this.id) : Promise.resolve({})
      Promise.all([fetchAll, fetchOne]).then(res => {
        let [{data: data1}, {data: data2}] = res
        if (this.id) {
          if (!data1.some(obj => obj.id === data2.id)) {
            data1.unshift(data2)
          }
        }
        this.sourceData = data1
        this.isLoading = false
        this.firstLoaded = true
      })
    },

    handleChange(val) {
      this.$emit('change', val)
    },
  }
}
</script>

<style lang='scss' scoped>

</style>