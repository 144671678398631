import { login, logout, fetchProfile } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import permission from './route';

function buildPermissionTree(permissions) {
  let permissionTree = {}
  permissions.forEach(permission => {
    let { name, parentName } = permission

    let lv1, lv2
    if (parentName) {
      [lv1, lv2] = [parentName, name]
    } else {
      lv1 = name
    }

    if (!permissionTree.hasOwnProperty(lv1)) {
      permissionTree[lv1] = []
    }

    if (lv2 && !permissionTree[lv1].includes(lv2)) {
      permissionTree[lv1].push(lv2)
    }
  })
  return permissionTree
}

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    permissions: {},
    manageAllStudios: null,
    role: null,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_MANAGE_ALL_STUDIOS: (state, manageAllStudios) => {
      state.manageAllStudios = manageAllStudios
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = buildPermissionTree(permissions)
    },
    SET_ROLE: (state, role) => {
      state.role = role
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const email = userInfo.email.trim()
      return new Promise((resolve, reject) => {
        login(email, userInfo.password)
          .then(resp => {
            setToken(resp.headers.authorization)
            commit('SET_TOKEN', resp.headers.authorization)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    fetchProfile({ commit, state }) {
      return new Promise((resolve, reject) => {
        fetchProfile()
          .then(response => {
            const data = response.data
            commit('SET_PERMISSIONS', data.permissions)
            commit('SET_NAME', data.name)
            commit('SET_AVATAR', data.avatar)
            commit('SET_MANAGE_ALL_STUDIOS', data.manageAllStudios)
            commit('SET_ROLE', data.role)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', null)
            removeToken()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', null)
        removeToken()
        resolve()
      })
    },
  },
}

export default user
