import Layout from 'views/layout/Layout'

export default {
  path: '/setting',
  redirect: '/setting/gate',
  name: 'setting',
  component: Layout,
  title: '系统管理',
  permission: ['system'],
  hidden: false,
  icon: 'setting',
  children: [
    {
      path: 'gate',
      component: () => import('views/setting/gate/Index'),
      title: '门禁管理',
      keepAlive: true,
      name: 'SettingGate',
      permission: ['system', 'gate'],
      hidden: false,
    },
    {
      path: 'messages',
      component: () => import('views/setting/messages/Index'),
      title: '短信管理',
      keepAlive: true,
      name: 'SettingMessage',
      permission: ['system', 'message'],
      hidden: false,
    },
    {
      path: 'admins',
      component: () => import('views/setting/admins/Index'),
      title: '帐号管理',
      keepAlive: true,
      name: 'SettingAdmins',
      permission: ['system', 'admin'],
      hidden: false,
    },
    {
      path: 'permissions',
      component: () => import('views/setting/permissions/Index'),
      title: '权限管理',
      keepAlive: true,
      name: 'SettingPermissions',
      permission: ['system', 'role'],
      hidden: false,
    },
  ],
}
