// Progress 进度条
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'

import { Message } from 'element-ui'

import router from '@/router'
import store from '@/store'

const whiteList = ['/login'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  // 同一页面内，通过id来滚动页面时不显示NProgress
  if (to.path !== from.path) {
    NProgress.start()
  }
  if (store.getters.token) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      //TODO: clean up code
      if (Object.keys(store.getters.permissions).length === 0) {
        store.dispatch('fetchProfile').then(res => { // 拉取用户信息
          
          const permissions = res.data.permissions 

          store.commit('SET_PERMISSIONS', permissions)

          store.dispatch('GenerateRoutes', { permissions: store.getters.permissions }).then(() => { // 根据roles权限生成可访问的路由表
            router.addRoutes(store.getters.permittedRoutes) // 动态添加可访问路由表
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
          })
        }).catch((err) => {
          //TODO: logout for 401 response only
          store.dispatch('FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again')
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // 结束Progress
})
