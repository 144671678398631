import request from 'utils/request'

// 活动列表
export function fetchCampaigns(params) {
  return request({
    url: '/campaigns',
    method: 'get',
    params,
  })
}

// 编辑活动
export function editCampaign(campaignId, data) {
  return request({
    url: `/campaigns/${campaignId}`,
    method: 'put',
    data,
  })
}

// 新建活动
export function addCampaign(data) {
  return request({
    url: `/campaigns`,
    method: 'post',
    data,
  })
}

// 活动详情
export function fetchCampaign(campaignId) {
  return request({
    url: `/campaigns/${campaignId}`,
    method: 'get',
  })
}

// 年报留言列表
export function fetchAnnualNotes(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/annual_notes`,
    method: 'get',
    params,
  })
}

// 年报留言详情
export function fetchAnnualNote(annualNoteId) {
  return request({
    url: `/annual_notes/${annualNoteId}`,
    method: 'get',
  })
}

// 修改年报留言
export function updateAnnualNote(annualNoteId, data) {
  return request({
    url: `/annual_notes/${annualNoteId}`,
    method: 'put',
    data
  })
}

// 活动照片列表
export function fetchCampaignImages(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/images`,
    method: 'get',
    params,
  })
}

// 添加活动照片
export function addCampaignImage(campaignId, data) {
  return request({
    url: `/campaigns/${campaignId}/images`,
    method: 'post',
    data,
  })
}

// 活动相册列表
export function fetchCampaignCampaignImages(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/campaign_images`,
    method: 'get',
    params,
  })
}

// 添加活动相册
export function addCampaignCampaignImage(campaignId, data) {
  return request({
    url: `/campaigns/${campaignId}/campaign_images`,
    method: 'post',
    data,
  })
}

// 批量上传活动相册
export function importCampaignCampaignImage(campaignId, data) {
  return request({
    url: `/campaigns/${campaignId}/campaign_images/import`,
    method: 'post',
    data,
  })
}

// 删除活动相册
export function deleteCampaignCampaignImage(imageId) {
  return request({
    url: `/campaign_images/${imageId}`,
    method: 'delete'
  })
}

// 活动背景图列表
export function fetchCampaignBgImages(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/bg_images`,
    method: 'get',
    params,
  })
}

// 添加背景图相册
export function addCampaignBgImage(campaignId, data) {
  return request({
    url: `/campaigns/${campaignId}/bg_images`,
    method: 'post',
    data,
  })
}

// 删除活动背景图
export function deleteCampaignBgImage(campaignId, imageId) {
  return request({
    url: `/campaigns/${campaignId}/bg_images/${imageId}`,
    method: 'delete'
  })
}

// 活动会员列表
export function fetchMembers(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/members`,
    method: 'get',
    params,
  })
}

// 活动课程列表(已添加)
export function fetchCapaignCourses(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/courses`,
    method: 'get',
    params,
  })
}

// 活动课程列表
export function fetchCourses(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/courses_by_studio`,
    method: 'get',
    params,
  })
}

// 添加活动课程
export function addCourses(campaignId, params) {
  return request({
    url: `/campaigns/${campaignId}/courses`,
    method: 'get',
    params,
  })
}

// 活动照片审核通过
export function picturePass(campaignId, imageId) {
  return request({
    url: `/campaigns/${campaignId}/images/${imageId}/pass`,
    method: 'put',
  })
}

// 活动照片审核不通过
export function pictureFail(campaignId, imageId) {
  return request({
    url: `/campaigns/${campaignId}/images/${imageId}/fail`,
    method: 'put',
  })
}

// 添加活动课程
export function addCourse(campaignId, data) {
  return request({
    url: `/campaigns/${campaignId}/courses`,
    method: 'post',
    data,
  })
}

// 删除活动课程
export function deleteCourse(campaignId, courseId) {
  return request({
    url: `/campaigns/${campaignId}/courses/${courseId}`,
    method: 'delete',
  })
}

// 更改活动排序
export function moveTo(campaignId, data) {
  return request({
    url: `/campaigns/${campaignId}/move_to`,
    method: 'put',
    data
  })
}

// 会员邀请列表
export function fetchReferrals(params) {
  return request({
    url: `/member/member_referrals`,
    method: 'get',
    params,
  })
}

// 会员邀请统计
export function fetchReferralsOverview(params) {
  return request({
    url: `/member/member_referrals/overview`,
    method: 'get',
    params,
  })
}

// 会员邀请统计
export function fetchBookings(params) {
  return request({
    url: `/operation/bookings`,
    method: 'get',
    params,
  })
}

// 教练结算概览
export function fetchBillsOverview(params) {
  return request({
    url: `/operation/bills/overview`,
    method: 'get',
    params,
  })
}

// 教练结算列表
export function fetchBillsReport(params) {
  return request({
    url: `/operation/bills/report`,
    method: 'get',
    params,
  })
}

// 运营当月课程单价
export function fetchCurrentCoursePrice(params) {
  return request({
    url: `/operation/prices/current`,
    method: 'get',
    params,
  })
}

// 运营课程单价
export function fetchCoursePrice(params) {
  return request({
    url: `/operation/prices`,
    method: 'get',
    params,
  })
}

// 评价管理
export function fetchRatings(params) {
  return request({
    url: '/course/ratings',
    method: 'get',
    params,
  })
}

// 评价管理概览
export function fetchRatingsOverview(params) {
  return request({
    url: '/course/ratings/overview',
    method: 'get',
    params,
  })
}

// 标记评价已处理
export function completeRating(ratingId, params) {
  return request({
    url: `/course/ratings/${ratingId}/complete`,
    method: 'put',
    params,
  })
}

// 心率记录列表
export function fetchTrainingResults(params) {
  return request({
    url: `/course/training_results`,
    method: 'get',
    params,
  })
}

// 团课结算报告
export function fetchSettlements(params) {
  return request({
    url: '/operation/group_training_settlement/report',
    method: 'get',
    params,
  })
}

// 心率记录统计
export function fetchTrainingResultOverview(params) {
  return request({
    url: `/course/training_results/overview`,
    method: 'get',
    params,
  })
}

// 评论列表
export function fetchReviews(params) {
  return request({
    url: '/course/reviews',
    method: 'get',
    params,
  })
}

// 编辑评论展示位置
export function editReviewDisplay(reviewId, data) {
  return request({
    url: `/course/reviews/${reviewId}`,
    method: 'put',
    data,
  })
}

// 优惠券列表
export function fetchCoupons(params) {
  return request({
    url: `/coupons`,
    method: 'get',
    params,
  })
}

// 评论审核通过
export function auditReview(reviewId) {
  return request({
    url: `/course/reviews/${reviewId}/audit`,
    method: 'put',
  })
}

// 评论审核不通过
export function failReview(reviewId) {
  return request({
    url: `/course/reviews/${reviewId}/fail`,
    method: 'put',
  })
}

// pin review -- 暂时没有使用
export function pinReview(reviewId) {
  return request({
    url: `/course/reviews/${reviewId}/pin`,
    method: 'put',
  })
}

// 批量评论审核通过
export function batchReview(data) {
  return request({
    url: `/course/reviews/batch_audit`,
    method: 'get',
    params,
  })
}

// 优惠券详情
export function fetchCoupon(couponId, params) {
  return request({
    url: `/coupons/${couponId}`,
    method: 'get',
    params,
  })
}

// 新建优惠券
export function addCoupon(data) {
  return request({
    url: `/coupons`,
    method: 'post',
    data,
  })
}

// 修改优惠券
export function editCoupon(couponId, data) {
  return request({
    url: `/coupons/${couponId}`,
    method: 'put',
    data,
  })
}

// 促销列表
export function fetchCouponPromotions(params) {
  return request({
    url: `/coupon/promotions`,
    method: 'get',
    params,
  })
}

// 促销详情
export function fetchCouponPromotion(promotionId, params) {
  return request({
    url: `/coupon/promotions/${promotionId}`,
    method: 'get',
    params,
  })
}

// 新建促销
export function addCouponPromotion(data) {
  return request({
    url: `/coupon/promotions`,
    method: 'post',
    data,
  })
}

// 修改促销
export function editCouponPromotion(promotionId, data) {
  return request({
    url: `/coupon/promotions/${promotionId}`,
    method: 'put',
    data,
  })
}

// 优惠券获取记录
export function fetchCouponRedemptions(promotionId, params) {
  return request({
    url: `/coupon/promotions/${promotionId}/redemptions`,
    method: 'get',
    params,
  })
}

// 运营营销列表
export function fetchOperatingPromotions(params) {
  return request({
    url: `/coupons/operating_promotions`,
    method: 'get',
    params,
  })
}

// 创建运营营销
export function addOperatingPromotion(data) {
  return request({
    url: `/coupons/operating_promotions`,
    method: 'post',
    data,
  })
}

// 删除运营营销
export function deleteOperatingPromotion(promotionId) {
  return request({
    url: `/coupons/operating_promotions/${promotionId}`,
    method: 'put',
  })
}

// 运营事件列表
export function fetchSpecialEvents(params) {
  return request({
    url: `special_events`,
    method: 'get',
    params,
  })
}

// 新到店会员列表
export function fetchNewComing(params) {
  return request({
    url: `members/new_coming`,
    method: 'get',
    params,
  })
}

// 团购列表
export function fetchOrderGroups(params) {
  return request({
    url: `/order_groups`,
    method: 'get',
    params,
  })
}

// 强制成团
export function makeOrderGroupsGroup(orderGroupId) {
  return request({
    url: `/order_groups/${orderGroupId}/group`,
    method: 'put',
  })
}

// 取消拼团
export function cancelOrderGroupsGroup(orderGroupId) {
  return request({
    url: `/order_groups/${orderGroupId}/fail`,
    method: 'put',
  })
}

// 提交财务退款
export function submitOrderGroupsRefund(orderGroupId) {
  return request({
    url: `/order_groups/${orderGroupId}/submit_refund`,
    method: 'put',
  })
}

// 确认退款
export function confirmOrderGroupsRefund(orderGroupId) {
  return request({
    url: `/order_groups/${orderGroupId}/refund`,
    method: 'put',
  })
}
