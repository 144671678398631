import { addAllOption } from '@/utils/'

export const POSITION_OPTION = [
  {
    name: '空',
    value: '',
  },
  {
    name: 'A',
    value: 'A',
  },
  {
    name: 'B',
    value: 'B',
  },
  {
    name: 'C',
    value: 'C',
  },
  {
    name: 'D',
    value: 'D',
  },
  {
    name: 'DC',
    value: 'DC',
  },
]

export const ACCOUNT_STATUS_OPTION = addAllOption([
  {
    name: '尚有有效期',
    value: 'sufficient',
  },
  {
    name: '有效期充足',
    value: 'available',
  },
  {
    name: '会员卡到期提醒',
    value: 'expire_soon',
  },
  {
    name: '会员卡已过期',
    value: 'expired',
  },
])

export const SALE_TYPE_OPTION = addAllOption([
  {
    name: '促销包',
    value: 'normal',
  },
  {
    name: '普通包',
    value: 'promotion',
  },
  {
    name: '新手包',
    value: 'newbie',
  },
])

export const GENDER_OPTION = addAllOption([
  {
    name: '男',
    value: '男',
  },
  {
    name: '女',
    value: '女',
  },
])

export const GENDER_OPTION_WITHOUT_ALL = [
  {
    name: '男',
    value: '男',
  },
  {
    name: '女',
    value: '女',
  },
]

export const PRODUCT_TYPE = addAllOption([
  {
    name: '团课',
    value: 'group_training',
  },
])

export const TRIAL_MEMBER_OPTION = addAllOption([
  {
    name: '待激活',
    value: 'new',
  }, {
    name: '已激活',
    value: 'effective',
  }, {
    name: '已过期',
    value: 'expired',
  }
])

export const ELITE_TRAINING_TRIAL_MEMBER_OPTION = addAllOption([
  {
    name: '待激活',
    value: 'new',
  }, {
    name: '已激活',
    value: 'effective',
  }, {
    name: '已过期',
    value: 'expired',
  }
])

export const EXPIRE_REASON_OPTION = ['考虑中', '强度太大', '距离太远', '课程太贵', '不太喜欢这种模式', '没有联系到会员', '其他']
export const ELITE_TRAINING_EXPIRE_REASON_OPTION = ["无法满足用户需求", "教练服务不到位", "教练执教有所保留", "课程价格太贵", "门店距离太远", "门店环境", "考虑中", "无法联系会员", "其他"]

export const PAYMENTMETHOD = [{
  label: '微信钱包',
  value: '微信钱包',
}, {
  label: '大众点评',
  value: '大众点评',
}, {
  label: '微信转账',
  value: '微信转账',
}, {
  label: '支付宝转账',
  value: '支付宝转账',
}, {
  label: '现金',
  value: '现金',
}, {
  label: 'POS机',
  value: 'POS机',
}]

export const PRESET_COURSE_TAGS = [{
  label: '新课体验',
  value: '新课体验',
}, {
  label: '新套路',
  value: '新套路',
}, {
  label: '新教练',
  value: '新教练',
}]