import Layout from 'views/layout/Layout'

export default {
  path: '/finance',
  redirect: '/finance/sales',
  name: 'finance',
  component: Layout,
  title: '销售报告',
  hidden: false,
  icon: 'finance',
  permission: ['finance'],
  children: [
    {
      path: 'sales',
      component: () => import('views/finance/Sales'),
      name: 'financeSales',
      title: '销售报告',
      hidden: false,
      permission: ['finance', 'sales_report'],
    },
    {
      path: 'bills',
      component: () => import('views/finance/Bills'),
      name: 'financeBills',
      title: '结算报告',
      hidden: false,
      permission: ['finance', 'bills_report'],
    },
    {
      path: 'settlement',
      component: () => import('views/finance/settlement/Index'),
      title: '团课营收',
      keepAlive: true,
      name: 'financeSettlement',
      permission: ['finance', 'group_training_settlements'],
      hidden: false,
    },
  ],
}
